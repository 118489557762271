import { Outlet, createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: async ({ context, location }) => {
    const { isAuthenticated, loginWithRedirect, user, isLoading } = context
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ appState: { returnTo: location.href } })
    }

    return { user }
  },
  component: AuthenticatedLayout,
})

function AuthenticatedLayout() {
  return <Outlet />
}
