import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useState, useEffect, useRef, useMemo } from 'react'

import Box from '@mui/material/Box'
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridPaginationMeta,
  useGridApiRef,
} from '@mui/x-data-grid'

import { Leaf, ScrollText, X } from 'lucide-react'

import ControlPanel from '@/components/ui/base/control-panel'
import PageLayout from '@/components/ui/layouts/page-layout.tsx'

import { useControlPanel } from '@/hooks/useControlPanel'
import { ElasticsearchResponse } from '@/types'
import { format } from 'date-fns'
import Typography from '@mui/material/Typography'
import AddPlantListModal from '@/components/ui/modals/add-plant-list-modal'
import { useQuery } from '@tanstack/react-query'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import { usePubNub } from '@/contexts/PubNubContext'
import { getFileProcessingForPlantLists } from '@/api/ai-processed-plant-list-file'
import { getPlantLists } from '@/api/plant-list.ts'
import { DarkPrimaryButton } from '@/components/ui/base/buttons/buttons'
import theme from '@/theme.ts'

export const Route = createFileRoute('/_authenticated/plants/')({
  component: PlantsIndex,
})

// Example Plant type
type Plant = {
  id: string
  common_name: string
  scientific_name: string
  family: string
  status: string
}

function PlantsIndex() {
  const navigate = useNavigate()
  const { selectedOrganization } = useOrganization()
  const { subscribeToChannels, unsubscribeFromChannels } = usePubNub()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [actualRowCount, setActualRowCount] = useState(0)
  const [isAddPlantListModalOpen, setIsAddPlantListModalOpen] = useState(false)
  const [_searchResults, setSearchResults] =
    useState<ElasticsearchResponse<Plant> | null>(null)
  const { activeTab, setActiveTab, filterValues, handleFilterChange } =
    useControlPanel({
      defaultTab: 'by-list',
      defaultFilters: {
        uploaded_by: 'uploaded_by_anyone',
        timeframe: 'anytime',
        job_status: 'all_jobs',
        plant_status: 'all_plants',
      },
    })

  const { data: plantLists } = useQuery({
    queryKey: ['plant-lists', page, pageSize, selectedOrganization?.id],
    queryFn: async () => {
      if (!selectedOrganization) return
      return await getPlantLists(pageSize, page, 'created_at', 'desc')
    },
    enabled: !!selectedOrganization,
  })

  const { data: fileProcessingData, refetch: refetchFileProcessing } = useQuery(
    {
      queryKey: [
        'file-processing-data',
        plantLists?.map((plantList) => plantList.id) ?? [],
      ],
      queryFn: ({ queryKey }) => {
        const [_, plantListIds] = queryKey as [string, string[]]
        if (!plantListIds || plantListIds.length === 0) return []
        return getFileProcessingForPlantLists(
          selectedOrganization?.id,
          plantListIds
        )
      },
      enabled: !!plantLists?.length,
    }
  )

  const apiRef = useGridApiRef()
  const paginationMetaRef = useRef<GridPaginationMeta>()
  const hasNextPage = useMemo(() => {
    return plantLists?.length === pageSize
  }, [plantLists?.length, pageSize])

  const rowCount = useMemo(() => {
    // If we have less items than page size, we know the total count
    if (plantLists && plantLists.length < pageSize) {
      const count = page * pageSize + plantLists.length

      setActualRowCount(count)
      return count
    }
    // Otherwise, we have at least one more page (most likely)
    return -1
  }, [plantLists, pageSize, page])

  const paginationMeta = useMemo(() => {
    if (paginationMetaRef.current?.hasNextPage !== hasNextPage) {
      paginationMetaRef.current = { hasNextPage }
    }
    return paginationMetaRef.current
  }, [hasNextPage])

  useEffect(() => {
    const channels = fileProcessingData
      ?.filter((data) => !data.processing_completed && !data.breaking_error)
      .map((data) => data.pubsub_channel)

    if (!channels || channels.length === 0) {
      return
    }

    const listener = async (messageEvent: any) => {
      const { model } = messageEvent.message

      if (model.processing_completed || model.breaking_error) {
        refetchFileProcessing()
      }
    }

    subscribeToChannels(channels, 'plantList', listener)

    return () => {
      unsubscribeFromChannels(channels, 'plantList')
    }
  }, [fileProcessingData, refetchFileProcessing])

  const handleRowClick = (params: GridRowParams) => {
    navigate({ to: `/plants/${params.row.id}` })
  }

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPage(model.page)
    setPageSize(model.pageSize)
  }

  const getPlantListStatus = (plantListId: string) => {
    const processingData = fileProcessingData?.filter(
      (data) => data.parent_plant_list_id === plantListId
    )

    if (!processingData?.length) return 'ready'

    if (processingData.some((data) => data.breaking_error)) return 'error'
    if (processingData.some((data) => !data.processing_completed))
      return 'processing'
    return 'ready'
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => params.value,
    },
    {
      field: 'created_at',
      headerName: 'Created On',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: (value?: string) => {
        if (!value) return ''
        return format(value.endsWith('Z') ? value : value + 'Z', 'PPP, ppp')
      },
    },
    {
      field: 'entries_count',
      headerName: 'List Size',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: (value: number) => value.toLocaleString() + ' Plants',
    },
    {
      field: 'id',
      headerName: 'Status',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (value) => {
        // TODO: for ready status, check assignment status of entries
        return getPlantListStatus(value)
      },
      renderCell: (params) => {
        switch (params.value) {
          case 'processing':
            return (
              <Chip
                icon={<CircularProgress size={14} color="inherit" />}
                label="Processing"
                size="medium"
                color="warning"
              />
            )
          case 'ready':
          case 'pending':
            return <Chip label="Pending" size="medium" />
          case 'error':
            return (
              <Chip
                icon={<X size={14} />}
                label="Error"
                color="error"
                size="small"
              />
            )
          default:
            return <Chip label="Unknown" size="medium" />
        }
      },
    },
  ]

  const handlePlantListCreated = (newPlantListId: string) => {
    // Navigate to the new plant list detail page
    navigate({ to: `/plants/${newPlantListId}` })
  }

  return (
    <PageLayout title="Plants" icon={<Leaf size={36} />} globalNewButton>
      <ControlPanel
        tabConfig={{
          tabs: [
            { label: 'By List / Uploads', value: 'by-list' },
            { label: 'By Plant', value: 'by-plant' },
          ],
          activeTab: activeTab,
          onTabChange: setActiveTab,
        }}
        searchConfig={{
          placeholder: 'Search Plants...',
          indexName: 'plants',
          onSearchResults: setSearchResults,
        }}
        filterConfig={{
          filters: [
            {
              label: 'Uploaded By',
              value: 'uploaded_by',
              options: [
                { label: 'Uploaded By Anyone', value: 'uploaded_by_anyone' },
                { label: 'Uploaded By Me', value: 'uploaded_by_me' },
              ],
            },
            {
              label: 'Timeframe',
              value: 'timeframe',
              options: [
                { label: 'Anytime', value: 'anytime' },
                { label: 'Last 24 Hours', value: '24h' },
                { label: 'Last 7 Days', value: '7d' },
                { label: 'Last 30 Days', value: '30d' },
                { label: 'Last 90 Days', value: '90d' },
              ],
            },
            {
              label: 'Job Status',
              value: 'job_status',
              options: [
                { label: 'All Jobs', value: 'all_jobs' },
                { label: 'Active Jobs', value: 'active_jobs' },
                { label: 'Completed Jobs', value: 'completed_jobs' },
                { label: 'Cancelled Jobs', value: 'cancelled_jobs' },
              ],
            },
            {
              label: 'Plant Status',
              value: 'plant_status',
              options: [
                { label: 'All Plants', value: 'all_plants' },
                { label: 'Available', value: 'available_plants' },
                { label: 'Out of Stock', value: 'out_of_stock_plants' },
                { label: 'Discontinued', value: 'discontinued_plants' },
              ],
            },
          ],
          filterValues,
          onFilterChange: handleFilterChange,
        }}
      />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          borderRadius: theme.borderRadius.lg,
          backgroundColor: '#F3F3F3',
          p: 4,
          mb: 4,
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <ScrollText size={36} />
          <Box display="flex" flexDirection="column">
            <Typography variant="body1" fontWeight="bold">
              Upload a Plant List and then assign plants to Jobs, RFPs, Quotes,
              or Orders
            </Typography>
            <Typography variant="caption">
              PDF, JPG, PNG, .xlsx, WEBP, GIF, csv, .txt
            </Typography>
          </Box>
        </Box>
        <Box>
          <DarkPrimaryButton onClick={() => setIsAddPlantListModalOpen(true)}>
            <Typography variant="button">Select Files...</Typography>
          </DarkPrimaryButton>
        </Box>
      </Box>

      <Box sx={{ maxHeight: 600, width: '100%' }}>
        <DataGrid
          apiRef={apiRef}
          rows={plantLists || []}
          columns={columns}
          onRowClick={handleRowClick}
          initialState={{
            pagination: {
              paginationModel: { page, pageSize },
            },
            sorting: { sortModel: [{ field: 'created_at', sort: 'desc' }] },
          }}
          rowCount={actualRowCount || rowCount}
          pageSizeOptions={[10, 25, 50]}
          paginationMode="server"
          paginationMeta={paginationMeta}
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={handlePaginationModelChange}
          disableColumnMenu
          disableColumnSorting
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
          }}
        />
      </Box>

      <AddPlantListModal
        open={isAddPlantListModalOpen}
        onClose={() => setIsAddPlantListModalOpen(false)}
        onPlantListCreated={handlePlantListCreated}
      />
    </PageLayout>
  )
}
