import { useQuery } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createFileRoute, Link } from '@tanstack/react-router'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import { ArrowLeft, Building2 } from 'lucide-react'

import ContactCard from '@/components/contact-details/contact-card.tsx'
import ContactTabs from '@/components/contact-details/contact-tabs.tsx'
import GlobalNewButton from '@/components/ui/global-new-button.tsx'

import { getContactDetails } from '@/api/contacts.ts'
import { ContactTypes } from '@/constants.ts'

export const Route = createFileRoute('/_authenticated/contacts/$contactId')({
  component: ContactDetail,
})

function ContactDetail() {
  const { contactId } = Route.useParams()
  const { data, error, isLoading, isFetching } = useQuery({
    queryKey: ['contact', contactId],
    queryFn: () => getContactDetails(contactId),
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  if (!data) {
    return <div>Contact not found</div>
  }

  const contactOrganization =
    data.contact.type === ContactTypes.USER &&
    data.organization_contact?.length ? (
      <>
        <Divider sx={{ my: 4 }} />
        <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold', mb: 1 }}>
          Organizations
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          {data.organization_contact?.map((orgContact) => (
            <Link key={orgContact.id} to={`/contacts/${orgContact.id}`}>
              <Box
                display="flex"
                gap={1}
                sx={{
                  border: 1,
                  borderColor: 'divider',
                  borderRadius: '10px',
                  p: 2,
                }}
              >
                <Building2 />
                <Typography variant="body1">
                  {orgContact.organization_name}
                </Typography>
              </Box>
            </Link>
          ))}
        </Box>
      </>
    ) : null

  return (
    <Box display="flex" gap={4} sx={{ height: '100%', p: 4 }}>
      {/* Contact Sidebar */}
      <Box sx={{ width: 372 }}>
        <Link to="/contacts">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <ArrowLeft size={16} /> All Contacts
            </Box>
            {isFetching && <CircularProgress size={16} />}
          </Box>
        </Link>
        <Divider sx={{ mt: 3, mb: 4 }} />
        <ContactCard contactDetails={data} />
        {contactOrganization}
      </Box>

      <Divider orientation="vertical" />

      {/* Content */}
      <Box>
        <Box sx={{ position: 'absolute', top: 16, right: 32, zIndex: 100 }}>
          <GlobalNewButton />
        </Box>
        <ContactTabs />
      </Box>

      <ReactQueryDevtools initialIsOpen={false} />
    </Box>
  )
}
