import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

export const StyledVerticalTabs = styled(Tabs)(({ theme }) => ({
  width: '300px',
  paddingTop: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}))

export const StyledVerticalTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.primary,
  textAlign: 'left',
  textTransform: 'none',
  alignItems: 'flex-start',
  padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
  borderRadius: theme.borderRadius.md,
  marginBottom: theme.spacing(1),
  fontSize: theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightRegular,
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}))
