import {
  type QueryObserverResult,
  type RefetchOptions,
} from '@tanstack/react-query'
import React, { createContext, type ReactNode } from 'react'

import { UserInfo } from '@/types.ts'

type UserInfoContextType = {
  userInfo: UserInfo | undefined
  refetchUserInfo: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<UserInfo, Error>>
}
export const UserInfoContext = createContext<UserInfoContextType | undefined>(
  undefined
)

type UserInfoProviderProps = {
  children: ReactNode
  userInfo: UserInfo | undefined
  refetchUserInfo: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<UserInfo, Error>>
}
export const UserInfoProvider: React.FC<UserInfoProviderProps> = ({
  children,
  userInfo,
  refetchUserInfo,
}) => {
  return (
    <UserInfoContext.Provider value={{ userInfo, refetchUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  )
}
