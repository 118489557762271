import { useAuth0 } from '@auth0/auth0-react'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useEffect } from 'react'

export const Route = createFileRoute('/auth0-callback')({
  component: Callback,
})

function Callback() {
  const { isLoading, error } = useAuth0()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading && !error) {
      navigate({ to: '/' })
    }
  }, [isLoading, error, navigate])

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Oops... {error.message}</div>
  }

  return null
}
