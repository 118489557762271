import { useState, useRef, useEffect, type MouseEvent } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'

import DeleteIcon from '@mui/icons-material/Delete'
import { ZoomInIcon, ZoomOutIcon } from 'lucide-react'

import { deleteFile } from '@/api/files.ts'
import { IMAGE_FILE_TYPES, SPREADSHEET_FILE_TYPES } from '@/constants.ts'
import { fetchGCSFile } from '@/lib/gcs-file-fetcher.ts'
import type { GCSFile, ImageFile, SpreadsheetFile } from '@/types.ts'
import { IconButton, Stack } from '@mui/material'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'

type FileViewerProps = {
  files: GCSFile[]
  onFileDelete: () => void
  targetFileId?: string | null
}

export default function FileViewerAlternative({
  files,
  onFileDelete,
  targetFileId,
}: FileViewerProps) {
  const [selectedFileIndex, setSelectedFileIndex] = useState(0)
  const [selectedFileId, setSelectedFileId] = useState<string>('')
  const [isZoomed, setIsZoomed] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(1)
  const [savedZoomLevel, setSavedZoomLevel] = useState(2)
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState(false)
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 })
  const [clickStartTime, setClickStartTime] = useState(0)
  const imageRef = useRef<HTMLImageElement>(null)
  const imageContainerRef = useRef<HTMLDivElement>(null)

  const availableFiles = files.filter((file) => !file.deleted_at)

  useEffect(() => {
    if (
      availableFiles.length > 0 &&
      (!selectedFileId ||
        !availableFiles.some((file) => file.id === selectedFileId))
    ) {
      setSelectedFileId(availableFiles[0].id)
    } else if (availableFiles.length === 0) {
      setSelectedFileId('')
    }
  }, [files, selectedFileId])

  useEffect(() => {
    if (targetFileId) {
      const newIndex = availableFiles.findIndex(
        (file) => file.id === targetFileId
      )
      if (newIndex !== -1) {
        setSelectedFileId(targetFileId)
        setSelectedFileIndex(newIndex)
      }
    }
  }, [targetFileId])

  const { data: selectedFile } = useQuery({
    queryKey: ['file', selectedFileId],
    queryFn: async () => {
      const file = files.find((file) => file.id === selectedFileId)!
      return await fetchGCSFile(file)
    },
    enabled: !!selectedFileId,
  })

  const deleteMutation = useMutation({
    mutationFn: deleteFile,
    onSuccess: (_, deletedFileId) => {
      const remainingFiles = files.filter(
        (file) => file.id !== deletedFileId && !file.deleted_at
      )
      if (remainingFiles.length > 0) {
        setSelectedFileId(remainingFiles[0].id)
      } else {
        setSelectedFileId('')
      }
      onFileDelete()
    },
  })

  const handleFileIndex = (direction: 'prev' | 'next') => {
    const newIndex =
      direction === 'prev'
        ? (selectedFileIndex - 1 + availableFiles.length) %
          availableFiles.length
        : (selectedFileIndex + 1) % availableFiles.length

    setSelectedFileIndex(newIndex)
    setSelectedFileId(availableFiles[newIndex].id)
  }

  const handleFileDelete = async (deleteFileId: string) => {
    if (deleteFileId) {
      await deleteMutation.mutateAsync(deleteFileId)
    }
  }

  const handleZoomToggle = (event: MouseEvent<HTMLDivElement>) => {
    if (!isZoomed) {
      if (imageContainerRef.current) {
        const rect = imageContainerRef.current.getBoundingClientRect()
        const x = ((event.clientX - rect.left) / rect.width) * 100
        const y = ((event.clientY - rect.top) / rect.height) * 100
        setPanPosition({ x, y })
      }
      setZoomLevel(savedZoomLevel)
    } else {
      setPanPosition({ x: 0, y: 0 })
      setZoomLevel(1)
    }

    setIsZoomed(!isZoomed)
  }

  const handleZoomChange = (_event: Event, newValue: number | number[]) => {
    const newZoom = newValue as number
    setZoomLevel(newZoom)
    setIsZoomed(newZoom > 1)
    if (newZoom <= 1) {
      setSavedZoomLevel(2)
      setPanPosition({ x: 0, y: 0 })
    } else {
      setSavedZoomLevel(newZoom)
    }
  }

  const handleZoomIn = () => {
    setZoomLevel((prev) => {
      const newZoom = Math.min(prev + 0.5, 5)
      setSavedZoomLevel(newZoom)
      return newZoom
    })
    setIsZoomed(true)
  }

  const handleZoomOut = () => {
    setZoomLevel((prev) => {
      const newZoom = Math.max(prev - 0.5, 1)
      setIsZoomed(newZoom > 1)
      if (newZoom <= 1) {
        setSavedZoomLevel(2)
        setPanPosition({ x: 0, y: 0 })
      } else {
        setSavedZoomLevel(newZoom)
      }
      return newZoom
    })
  }

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setClickStartTime(Date.now())
    if (isZoomed) {
      setIsDragging(true)
      setDragStart({ x: event.clientX, y: event.clientY })
    }
  }

  const handleMouseUp = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    const clickDuration = Date.now() - clickStartTime
    if (clickDuration < 200) {
      handleZoomToggle(event)
    }
    setIsDragging(false)
  }

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (isZoomed && isDragging) {
      const dx = event.clientX - dragStart.x
      const dy = event.clientY - dragStart.y
      setDragStart({ x: event.clientX, y: event.clientY })
      setPanPosition((prev) => ({
        x: prev.x + dx / zoomLevel,
        y: prev.y + dy / zoomLevel,
      }))
    }
  }

  const selectedFileView = (
    <>
      {selectedFile &&
        SPREADSHEET_FILE_TYPES.includes(selectedFile.file_type) && (
          <Box className="max-h-[70vh] overflow-y-scroll">
            {(selectedFile as SpreadsheetFile)?.tables?.map((table, index) => (
              <Box
                key={index}
                dangerouslySetInnerHTML={{ __html: table }}
                id="file-view-container"
              ></Box>
            ))}
          </Box>
        )}
      {selectedFile && IMAGE_FILE_TYPES.includes(selectedFile.file_type) && (
        <>
          <Box
            ref={imageContainerRef}
            sx={{
              position: 'relative',
              overflow: 'hidden',
              height: '55vh',
              width: '100%',
              border: '1px solid #333',
              borderRadius: '0.5rem',
              paddingX: '2',
              cursor: isZoomed ? 'move' : 'zoom-in',
            }}
            onMouseUp={handleMouseUp}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
          >
            <img
              ref={imageRef}
              src={(selectedFile as ImageFile).url}
              alt={(selectedFile as ImageFile).alt}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
                transition: isZoomed ? 'none' : 'transform 0.1s ease-in-out',
                transform: `scale(${zoomLevel}) translate(${-panPosition.x / zoomLevel}%, ${-panPosition.y / zoomLevel}%)`,
                transformOrigin: '0 0',
                pointerEvents: 'none',
                userSelect: 'none',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              padding: '1em',
            }}
          >
            <Button onClick={handleZoomOut}>
              <ZoomOutIcon color="white" />
            </Button>

            <Slider
              value={zoomLevel}
              onChange={handleZoomChange}
              min={1}
              max={5}
              step={0.1}
              aria-labelledby="zoom-slider"
              sx={{
                flexGrow: 1,
                color: 'white',
                '& .MuiSlider-thumb': {
                  color: 'white',
                },
                '& .MuiSlider-track': {
                  color: 'white',
                },
                '& .MuiSlider-rail': {
                  color: 'white',
                },
              }}
            />
            <Button onClick={handleZoomIn}>
              <ZoomInIcon color="white" />
            </Button>
          </Box>
        </>
      )}
    </>
  )

  return (
    <Box sx={{ backgroundColor: '#000' }}>
      <Box px={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          color="white"
        >
          <Typography variant="body2">
            {selectedFile?.user_file_name}
          </Typography>
          <Stack direction="row" py={2} spacing={1} alignItems="center">
            <IconButton
              disabled={selectedFileIndex === 0}
              onClick={() => handleFileIndex('prev')}
              sx={{
                color: 'white',
                backgroundColor: 'grey.800',
                '&:hover': { backgroundColor: 'grey.900' },
                '&.Mui-disabled': {
                  color: 'grey.700',
                  backgroundColor: 'grey.800',
                  opacity: 0.5,
                },
              }}
            >
              <NavigateBefore />
            </IconButton>
            <Typography>{`${selectedFileIndex + 1} of ${availableFiles.length}`}</Typography>
            <IconButton
              disabled={selectedFileIndex === availableFiles.length - 1}
              onClick={() => handleFileIndex('next')}
              sx={{
                color: 'white',
                backgroundColor: 'grey.800',
                '&:hover': { backgroundColor: 'grey.900' },
                '&.Mui-disabled': {
                  color: 'grey.700',
                  backgroundColor: 'grey.800',
                  opacity: 0.5,
                },
              }}
            >
              <NavigateNext />
            </IconButton>
          </Stack>
        </Stack>
        {/* PDF rendering component */}
        {selectedFileView}
      </Box>

      <Box p={2} sx={{ borderTop: 1, borderColor: 'divider' }}>
        {availableFiles.map((file) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={0.4}
            key={file.id}
            sx={{
              pl: 1,
              color: 'white',
              border: 1,
              borderColor: 'grey.900',
              borderRadius: 2,
              '&:hover': { borderColor: 'white' },
            }}
          >
            <Typography>{file.user_file_name}</Typography>
            <Button
              onClick={() => handleFileDelete(file.id)}
              sx={{
                color: 'white',
                textDecoration: 'underline',
                '&:hover': { textDecoration: 'underline', color: 'red' },
              }}
              disabled={!selectedFileId || deleteMutation.isPending}
              startIcon={
                deleteMutation.isPending ? (
                  <CircularProgress size={16} />
                ) : (
                  <DeleteIcon />
                )
              }
            >
              Delete File
            </Button>
          </Stack>
        ))}
      </Box>
    </Box>
  )
}
