import { createFileRoute } from '@tanstack/react-router'
import Button from '@mui/material/Button'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import PageLayout from '@/components/ui/layouts/page-layout.tsx'

export const Route = createFileRoute('/')({
  component: Index,
})

function Index() {
  const { addToastNotification } = useToastNotifications()
  const randomSeverity = (choice: 'severity' | 'variant') => {
    let choices = []
    if (choice === 'severity') {
      choices = ['success', 'info', 'warning', 'error'] as const
    } else {
      choices = ['filled', 'outlined'] as const
    }
    return choices[Math.floor(Math.random() * choices.length)]
  }

  const envText =
    import.meta.env.VITE_ENV !== 'production'
      ? `(${import.meta.env.VITE_ENV})`
      : ''

  return (
    <PageLayout title={`Plantbid v1.5 ${envText}`}>
      <Button
        onClick={() =>
          addToastNotification({
            message: `Message ${crypto.randomUUID()}`,
            severity: randomSeverity('severity') as
              | 'success'
              | 'info'
              | 'warning'
              | 'error',
            variant: randomSeverity('variant') as 'filled' | 'outlined',
          })
        }
      >
        Test toast notification
      </Button>
    </PageLayout>
  )
}
