import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

type NotImplementedModalProps = {
  open: boolean
  onClose: () => void
}
export default function NotImplementedModal({
  open,
  onClose,
}: NotImplementedModalProps) {
  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: 600,
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h3" gutterBottom>
          Not Implemented
        </Typography>
        <Typography variant="body1">
          This feature is not yet implemented.
        </Typography>
        <Button onClick={onClose}>Close</Button>
      </Box>
    </Modal>
  )
}
