import { createFileRoute } from '@tanstack/react-router'
import { PlusIcon, ScrollText } from 'lucide-react'
import {
  Typography,
  Select,
  MenuItem,
  TextField,
  Box,
  Stack,
} from '@mui/material'

import PageLayout from '@/components/ui/layouts/page-layout'
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { addFileToPlantList, getPlantListById } from '@/api/plant-list.ts'
import { AIProcessingData, GCSFile, PlantList } from '@/types.ts'
import Divider from '@mui/material/Divider'
import {
  ACCEPTED_FILE_EXTENSIONS,
  ACCEPTED_FILE_TYPES,
  IMAGE_FILE_TYPES,
  MAX_IMAGE_DIMENSION,
} from '@/constants.ts'
import {
  getFileProcessingForPlantLists,
  processFileWithAI,
} from '@/api/ai-processed-plant-list-file.ts'
import PlantListTableAlternative from '@/components/plant-list-detail/plant-list-table-alt.tsx'
import Send from '@mui/icons-material/Send'
import {
  DarkPrimaryButton,
  TextPrimaryButton,
} from '@/components/ui/base/buttons/buttons.tsx'
import FileViewerAlternative from '@/components/plant-list-detail/file-viewer-alt.tsx'
import { createFile } from '@/api/files.ts'
import { uploadFile } from '@/api/gcs.ts'
import { checkImageDimensions, isImageFile } from '@/lib/utils.ts'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'

export function PlantTable({
  plantList,
  selectedFiles,
  selectedPlants,
  setSelectedPlants,
  setTargetFileId,
}: {
  plantList: PlantList
  selectedFiles: string[]
  selectedPlants: Set<string>
  setSelectedPlants: (plants: Set<string>) => void
  setTargetFileId: (fileId: string) => void
}) {
  const { selectedOrganization } = useOrganization()
  const { data: fileProcessingData } = useQuery({
    queryKey: ['file-processing-data', plantList.id] as const,
    queryFn: ({ queryKey }) => {
      const [_, id] = queryKey
      return getFileProcessingForPlantLists(selectedOrganization?.id, [
        id as string,
      ])
    },
  })
  const memoizedFileProcessingData = useMemo(() => {
    return fileProcessingData as AIProcessingData[]
  }, [fileProcessingData])

  return (
    <Box mt={4} mx={0}>
      <PlantListTableAlternative
        plantList={plantList}
        selectedFiles={selectedFiles}
        fileProcessingData={memoizedFileProcessingData}
        selectedPlants={selectedPlants}
        setSelectedPlants={setSelectedPlants}
        setTargetFileId={setTargetFileId}
      />
    </Box>
  )
}

export function PlantActionsHeader({
  plantList,
  selectedFiles,
  setSelectedFiles,
  selectedPlants,
}: {
  plantList: PlantList
  selectedFiles: string[]
  setSelectedFiles: (files: string[]) => void
  selectedPlants: Set<string>
}) {
  const plantListId = plantList.id
  const queryClient = useQueryClient()
  const { selectedOrganization } = useOrganization()
  const [error, setError] = useState('')
  const fileInputRef = useRef<HTMLInputElement>(null)
  const plantListFiles =
    plantList.files.filter(
      (file) =>
        IMAGE_FILE_TYPES.includes(file.file_type) && file.deleted_at === null
    ) || []

  const onFileUpload = () => {
    console.log('File uploaded success triggered')
  }

  const handlePlantListFileSelection = (event: any) => {
    setSelectedFiles(event.target.value)
  }

  const renderSelectedFiles = (selected: string[]) => {
    if (selected.length === 0) {
      return 'Select files to view plants'
    }
    const selectedFileCount = plantListFiles.length
    if (selectedFileCount === plantListFiles.length) {
      return `Viewing Plants from: All Files (${selectedFileCount})`
    } else if (selectedFileCount > 1) {
      return `Viewing Plants from: ${selectedFileCount} of ${plantListFiles.length} files`
    } else {
      return selected
        .map((id: string) => {
          const file = plantListFiles.find((file) => file.id === id)
          return `Viewing Plants from: ${file?.user_file_name}`
        })
        .join('')
    }
  }

  const uploadMutation = useMutation({
    mutationFn: async (file: File) => {
      const newFile: Partial<GCSFile> = {
        user_file_name: file.name,
        file_type: file.type,
        encoding: file.type,
        domain: 'plant_list',
      }
      const createdFile = await createFile(newFile)
      await uploadFile({
        file,
        contentType: file.type,
        putUrl: createdFile.put_url,
      })
      await addFileToPlantList(plantListId, createdFile)
      await processFileWithAI({
        organizationId: selectedOrganization?.id as string,
        fileId: createdFile.id,
        plantListId: plantListId,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['plantList', plantListId],
      })
      onFileUpload()
    },
  })

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) {
      return
    }

    if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
      setError(
        'Only files of the following format are allowed: .jpeg, .jpg, .png, .gif, .webp, .csv, .xlsx, and .pdf.'
      )
      return
    }

    if (isImageFile(file)) {
      const isValidDimension = await checkImageDimensions(file)
      if (!isValidDimension) {
        setError(
          `Image files must not exceed ${MAX_IMAGE_DIMENSION} pixels in width or height.`
        )
        return
      }
    }

    // ensure error is cleared
    setError('')
    uploadMutation.mutate(file)
  }

  const triggerFileInput = () => {
    fileInputRef.current?.click()
  }

  console.log('error', error)
  return (
    <Stack direction="column" justifyContent="space-between">
      <Stack direction="row" spacing={2}>
        <Select
          multiple={true}
          size="small"
          value={selectedFiles}
          sx={{ width: '50%' }}
          onChange={(e) => handlePlantListFileSelection(e)}
          renderValue={(selected) => renderSelectedFiles(selected)}
          displayEmpty
        >
          {plantListFiles.map((file, index) => (
            <MenuItem
              sx={{ margin: '0.5em', padding: 2, borderRadius: 2 }}
              key={`plant-list-file-selector-item-${index}`}
              value={file.id}
            >
              {file.user_file_name || 'n/a'}
            </MenuItem>
          ))}
        </Select>
        <Tooltip
          title={`Supported file types are: ${ACCEPTED_FILE_EXTENSIONS.join(', ')}`}
          arrow
          placement="top"
        >
          <TextPrimaryButton
            variant="outlined"
            color="primary"
            onClick={triggerFileInput}
            disabled={uploadMutation.isPending}
            startIcon={
              uploadMutation.isPending ? <CircularProgress size={16} /> : null
            }
          >
            Upload File
          </TextPrimaryButton>
        </Tooltip>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileUpload}
          accept={ACCEPTED_FILE_TYPES.join(',')}
        />
      </Stack>
      <Divider sx={{ marginY: '1em' }} />
      <Stack direction="row" justifyContent="space-between" py={1}>
        <TextField
          size="small"
          placeholder="Search Plants..."
          variant="outlined"
        />
        <Stack direction="row" spacing={2}>
          {selectedPlants.size ? (
            <TextPrimaryButton variant="outlined">
              Delete Selected
            </TextPrimaryButton>
          ) : null}
          <DarkPrimaryButton variant="contained" startIcon={<PlusIcon />}>
            Add Plants
          </DarkPrimaryButton>
        </Stack>
      </Stack>
    </Stack>
  )
}

export function PlantActionsFooter({
  selectedPlants,
}: {
  selectedPlants: Set<string>
}) {
  return (
    <Stack>
      <Stack mt={4} direction="row">
        <DarkPrimaryButton
          variant="contained"
          disabled={!selectedPlants.size}
          startIcon={<Send />}
        >
          Send Selected to ....
        </DarkPrimaryButton>
        <Typography
          pl={2}
          alignContent="center"
          fontSize="small"
          color={selectedPlants.size ? '' : 'grey.500'}
        >
          {selectedPlants.size} plants selected
        </Typography>
      </Stack>
      <Stack mt={4} spacing={2}>
        <Typography fontWeight="bold">
          Jobs associated with these plants
        </Typography>
        <Box>
          <Typography fontSize="small" color={'grey.500'}>
            No jobs associated
          </Typography>
        </Box>
      </Stack>
    </Stack>
  )
}

export function PlantListDetailContent({
  plantList,
}: {
  plantList: PlantList
}) {
  const [targetFileId, setTargetFileId] = useState<string | null>(null)
  const [selectedFiles, setSelectedFiles] = useState(
    plantList.files
      .filter(
        (file) => IMAGE_FILE_TYPES.includes(file.file_type) && !file.deleted_at
      )
      .map((file) => file.id)
  )
  const [selectedPlants, setSelectedPlants] = useState<Set<string>>(new Set())

  // Memoize the setSelectedPlants callback to prevent unnecessary re-renders
  const handleSetSelectedPlants = useCallback(
    (newSelectedPlants: Set<string>) => {
      setSelectedPlants(newSelectedPlants)
    },
    []
  )

  const refetchPlantList = async () => {}

  return (
    <Stack direction="row" maxWidth="100%">
      <Box flex={`0 0 60%`} pt={2}>
        <PlantActionsHeader
          plantList={plantList}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          selectedPlants={selectedPlants}
        />
        <PlantTable
          plantList={plantList}
          selectedFiles={selectedFiles}
          selectedPlants={selectedPlants}
          setSelectedPlants={handleSetSelectedPlants}
          setTargetFileId={setTargetFileId}
        />
        <PlantActionsFooter selectedPlants={selectedPlants} />
      </Box>
      <Box flex={`0 0 40%`} px={2}>
        <FileViewerAlternative
          files={plantList.files}
          onFileDelete={() => refetchPlantList()}
          targetFileId={targetFileId}
        />
      </Box>
    </Stack>
  )
}

export const Route = createFileRoute('/_authenticated/plants/$plantListId')({
  component: PlantListDetail,
})

function PlantListDetail() {
  const { plantListId } = Route.useParams()
  const {
    error,
    data: plantList,
    isFetching,
  } = useQuery({
    queryKey: ['plant-list', plantListId] as const,
    queryFn: ({ queryKey }) => {
      const [_, plantListId] = queryKey
      return getPlantListById(plantListId as string)
    },
  })

  console.log({ plantListId, plantList })
  return (
    <PageLayout
      title={plantList?.name || 'n/a'}
      icon={<ScrollText size={36} />}
      backRoute={{ to: '/plants', label: 'All Plant Lists' }}
    >
      {isFetching ? (
        <Typography>Loading...</Typography>
      ) : error ? (
        <Typography>Error: {error.message}</Typography>
      ) : (
        <PlantListDetailContent plantList={plantList as PlantList} />
      )}
    </PageLayout>
  )
}
