import { type ReactNode } from 'react'
import { type SxProps, type Theme } from '@mui/material/styles'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import { X } from 'lucide-react'

interface ModalHeaderProps {
  title: string
  variant?: 'modalHeader1' | 'modalHeader2'
  icon?: ReactNode
  showCloseButton?: true
  onCloseButtonClick?: () => void
  sx?: SxProps<Theme>
}
export default function ModalHeader({
  title,
  variant = 'modalHeader1',
  icon,
  showCloseButton,
  onCloseButtonClick,
  sx,
}: ModalHeaderProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={2}
      sx={sx}
    >
      <Box display="flex" alignItems="center" gap={1}>
        {icon}
        <Typography variant={variant}>{title}</Typography>
      </Box>
      {showCloseButton && onCloseButtonClick && (
        <IconButton onClick={onCloseButtonClick}>
          <X size={24} />
        </IconButton>
      )}
    </Box>
  )
}
