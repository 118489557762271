import { type ChangeEvent, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { US_STATES } from '@/constants.ts'
import type {
  BaseAddress,
  ContactDetails,
  ContactEmail,
  ContactPhone,
} from '@/types.ts'
import {
  initialFormAddress,
  initialFormEmail,
  initialFormPhone,
} from '@/seed_form_data.ts'
import { ContactAddress } from '@/types.ts'
import { countryFormattedPhoneNumber } from '@/lib/utils.ts'

type NewContactOrganizationFormProps = {
  contactDetails: ContactDetails
  onContactDetailsChange: (newDetails: Partial<ContactDetails>) => void
}
export default function ContactOrganizationForm({
  contactDetails,
  onContactDetailsChange,
}: NewContactOrganizationFormProps) {
  const [formAddress, setFormAddress] = useState(
    contactDetails?.address && contactDetails.address.length
      ? contactDetails.address
      : [initialFormAddress]
  )
  const [phoneNumbers, setPhoneNumbers] = useState(
    contactDetails.phone && contactDetails.phone.length
      ? contactDetails.phone
      : [initialFormPhone]
  )
  const [emailAddresses, setEmailAddresses] = useState(
    contactDetails.email && contactDetails.email.length
      ? contactDetails.email
      : [initialFormEmail]
  )

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number = 0
  ) => {
    const { name, value } = event.target

    const updateContactDetail = (
      field: 'phone' | 'email',
      subField: 'number' | 'address' | 'extension' | 'contact_type'
    ) => {
      const currentDetails = field === 'phone' ? phoneNumbers : emailAddresses
      const updatedDetails = [...currentDetails]
      const currentDetail = updatedDetails[index] || {}
      let parsed_value = value

      if (field === 'phone' && subField === 'number') {
        parsed_value = countryFormattedPhoneNumber(value)
      }

      updatedDetails[index] = {
        ...currentDetail,
        [subField]:
          subField === 'contact_type'
            ? (parsed_value as 'work' | 'mobile' | 'other')
            : parsed_value,
      }

      if (field === 'phone') {
        setPhoneNumbers(updatedDetails as ContactPhone[])
      } else {
        setEmailAddresses(updatedDetails as ContactEmail[])
      }

      return {
        [field]: updatedDetails,
      }
    }

    const updateAddress = (
      field: 'street' | 'suite' | 'city' | 'state' | 'zip'
    ) => {
      const updatedFormAddress = [...formAddress]
      const currentAddress = updatedFormAddress[index]
      updatedFormAddress[index] = {
        ...currentAddress,
        address: {
          ...currentAddress.address,
          [field]: value,
        } as BaseAddress,
      } as ContactAddress

      setFormAddress(updatedFormAddress)

      return { address: updatedFormAddress }
    }

    const inputHandlers: Record<string, () => Partial<ContactDetails>> = {
      phone_number: () => updateContactDetail('phone', 'number'),
      phone_extension: () => updateContactDetail('phone', 'extension'),
      phone_type: () => updateContactDetail('phone', 'contact_type'),
      email_address: () => updateContactDetail('email', 'address'),
      email_type: () => updateContactDetail('email', 'contact_type'),
      address_street: () => updateAddress('street'),
      address_suite: () => updateAddress('suite'),
      address_city: () => updateAddress('city'),
      address_state: () => updateAddress('state'),
      address_zip: () => updateAddress('zip'),
      default: () => ({ [name]: value }),
    }

    const handler = inputHandlers[name] || inputHandlers.default
    onContactDetailsChange(handler())
  }

  return (
    <Box>
      <TextField
        variant="outlined"
        name="organization_name"
        label="Organization Name"
        placeholder="Organization Name"
        fullWidth
        required
        value={contactDetails.organization_name || ''}
        onChange={handleInputChange}
        sx={{ mb: 2 }}
      />
      <Grid item xs={12} sx={{ marginTop: 1 }}>
        <Typography variant="subtitle1" gutterBottom>
          Address
        </Typography>
      </Grid>
      {formAddress.map((address: ContactAddress, index) => (
        <Grid container spacing={2} key={index} mb={1}>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              name="address_street"
              label="Street"
              placeholder="123 Main"
              fullWidth
              value={address?.address?.street || ''}
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              name="address_suite"
              label="Suite #"
              placeholder="Suite #"
              fullWidth
              value={address?.address?.suite || ''}
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              name="address_city"
              label="City"
              placeholder="City Name"
              fullWidth
              value={address?.address?.city || ''}
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              name="address_state"
              label="State"
              fullWidth
              select
              value={address?.address?.state || ''}
              onChange={(event) => handleInputChange(event, index)}
            >
              {US_STATES.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              name="address_zip"
              label="Zip Code"
              fullWidth
              value={address?.address?.zip || ''}
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Contact Details
        </Typography>
      </Grid>
      {phoneNumbers.map((phone, index) => (
        <Grid container spacing={2} key={index} mb={1}>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              name="phone_number"
              label="Phone"
              placeholder="+ (---) --- ----"
              fullWidth
              value={phone.number || ''}
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              name="phone_extension"
              label="Extension"
              placeholder="-"
              fullWidth
              value={phone.extension || ''}
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              name="phone_type"
              label="Type"
              fullWidth
              select
              value={phone.contact_type || ''}
              onChange={(event) => handleInputChange(event, index)}
            >
              <MenuItem value="work">Work</MenuItem>
              <MenuItem value="mobile">Mobile</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="text"
        color="inherit"
        size="small"
        sx={{ mb: 2 }}
        onClick={() =>
          setPhoneNumbers((prev) => {
            return [...(prev || []), initialFormPhone]
          })
        }
      >
        <Typography variant="button" textTransform="capitalize">
          + Add Another Phone
        </Typography>
      </Button>
      {emailAddresses.map((email, index) => (
        <Grid container spacing={2} key={index} mb={1}>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              name="email_address"
              label="Email"
              placeholder="info@acme.co"
              fullWidth
              value={email.address || ''}
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              name="email_type"
              label="Type"
              fullWidth
              select
              value={email.contact_type || ''}
              onChange={(event) => handleInputChange(event, index)}
            >
              <MenuItem value="work">Work</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="text"
        color="inherit"
        size="small"
        sx={{ mb: 2 }}
        onClick={() =>
          setEmailAddresses((prev) => {
            return [...(prev || []), initialFormEmail]
          })
        }
      >
        <Typography variant="button" textTransform="capitalize">
          + Add Another Email
        </Typography>
      </Button>
    </Box>
  )
}
