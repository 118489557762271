import { useState } from 'react'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import {
  DarkPrimaryButton,
  PrimaryCancelButton,
} from '@/components/ui/base/buttons/buttons.tsx'
import StyledPencilIcon from '@/components/ui/base/buttons/edit-icon-pencil-outlined.tsx'
import TimezonePicker from '@/components/ui/base/timezone-picker.tsx'
import { getTimezoneLabel, TimezoneInfo } from '@/lib/timezone.ts'

type ViewEditTimezoneProps = {
  userTimezone: string | null
  submitTimezoneHandler: (data: string) => Promise<void>
  validationError: string | null
}

const ViewEditTimezone = ({
  userTimezone,
  submitTimezoneHandler,
  validationError,
}: ViewEditTimezoneProps) => {
  const [timezone, setTimezone] = useState<string | null>(userTimezone)
  const [formValidationError, setFormValidationError] = useState<string | null>(
    validationError as string | null
  )
  const [editForm, setEditForm] = useState(false)

  const submitFormTimezone = async () => {
    if (timezone) {
      setFormValidationError(null)
      await submitTimezoneHandler(timezone)
      setEditForm(false)
    } else {
      setFormValidationError('Please select a timezone')
    }
  }

  const toggleEditTimezone = () => {
    setTimezone(timezone)
    setEditForm(!editForm)
  }

  const handleTimezoneChange = (timezoneInfo: TimezoneInfo | null) => {
    setTimezone(timezoneInfo?.value || null)
  }

  const timezoneLabel =
    (timezone && getTimezoneLabel(timezone)) ||
    'Please edit to select your timezone.'

  return editForm ? (
    <>
      <Typography variant="tabSection" mb={2}>
        Timezone
      </Typography>
      <TimezonePicker value={timezone} onChange={handleTimezoneChange} />
      <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
        <DarkPrimaryButton
          variant="outlined"
          color="inherit"
          onClick={submitFormTimezone}
        >
          Save Changes
        </DarkPrimaryButton>
        <PrimaryCancelButton
          variant="outlined"
          color="inherit"
          onClick={toggleEditTimezone}
        >
          Cancel
        </PrimaryCancelButton>
      </Box>
      {formValidationError && (
        <Alert severity="error" className="my-4">
          {formValidationError}
        </Alert>
      )}
    </>
  ) : (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box>
        <Typography variant="tabSection" mb={2}>
          Timezone
        </Typography>
        <Typography variant="body1">{timezoneLabel}</Typography>
      </Box>
      <Box>
        <StyledPencilIcon onClick={toggleEditTimezone} />
      </Box>
    </Box>
  )
}

export default ViewEditTimezone
