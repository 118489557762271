import api from './api.ts'
import type { PlantListEntry } from '@/types.ts'

export async function createRFP({
  id,
  name,
  entries,
}: {
  id: string
  name: string
  entries: PlantListEntry[]
}) {
  // find all plants that don't have a v1 match
  const plantsMissingV1Match = entries.filter((entry) => !entry.plant_id)
  if (plantsMissingV1Match.length > 0) {
    throw new Error(
      'Please provide a V1 match for all selected plants to create an RFP.'
    )
  }

  const body = {
    id,
    name,
    entries,
  }

  const response = await api.post('/v1/plantbid_internal/rfps/', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}
