import { useQueryClient } from '@tanstack/react-query'
import { type SyntheticEvent, useState } from 'react'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import {
  ArrowRightLeftIcon,
  Building2Icon,
  MapPinIcon,
  TruckIcon,
  UsersIcon,
} from 'lucide-react'

import DataSharing from '@/components/organization-settings-and-members/organization-data-sharing.tsx'
import DeliveryVehicles from '@/components/organization-settings-and-members/organization-delivery-vehicles.tsx'
import OrganizationDetails from '@/components/organization-settings-and-members/organization-details.tsx'
import Locations from '@/components/organization-settings-and-members/organization-locations.tsx'
import TeamMembers from '@/components/organization-settings-and-members/organization-team-members.tsx'
import a11yProps from '@/components/ui/a11y-props.ts'
import TabLabel from '@/components/ui/base/tabs/tab-label'
import TabPanel from '@/components/ui/base/tabs/tab-panel.tsx'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import {
  StyledVerticalTab,
  StyledVerticalTabs,
} from '@/components/ui/base/tabs/styled-tabs'
import { type SxProps } from '@mui/material'

export default function SettingsAndMembersTabs() {
  const { selectedOrganization } = useOrganization()
  const [value, setValue] = useState(0)
  const [highlightId, setHighlightId] = useState(false)

  const queryClient = useQueryClient()

  const refetchOrganization = () => {
    if (selectedOrganization) {
      queryClient.invalidateQueries({ queryKey: ['selected-organization'] })
    }
  }

  const switchToOrganizationTab = (value: boolean) => {
    setValue(0) // Switch to organization tab - index 0
    setHighlightId(value)
  }

  const handleHighlightAnimationComplete = () => {
    setHighlightId(false)
  }

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
    setHighlightId(false)
  }

  if (!selectedOrganization) {
    return null
  }

  const tabPanelSx: SxProps = {
    py: 5,
    pr: 5,
  }

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        display: 'flex',
        height: '100%',
        overflowY: 'hidden',
      }}
    >
      <StyledVerticalTabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="organization settings tabs"
      >
        {[
          { label: 'Organization', icon: <Building2Icon /> },
          { label: 'Locations', icon: <MapPinIcon /> },
          { label: 'Team Members', icon: <UsersIcon /> },
          { label: 'Delivery Vehicles', icon: <TruckIcon /> },
          { label: 'Data Sharing', icon: <ArrowRightLeftIcon /> },
        ].map((tab, index) => (
          <StyledVerticalTab
            key={index}
            label={<TabLabel label={tab.label} icon={tab.icon} />}
            sx={{
              color: 'text.primary',
              textAlign: 'left',
              textTransform: 'none',
              alignItems: 'flex-start',
            }}
            {...a11yProps(index, 'org-settings')}
          />
        ))}
      </StyledVerticalTabs>

      <Divider orientation="vertical" flexItem sx={{ m: 5 }} />

      <TabPanel
        id="org-settings-details"
        index={0}
        value={value}
        sx={tabPanelSx}
      >
        <OrganizationDetails
          highlightId={highlightId}
          onHighlightAnimationComplete={handleHighlightAnimationComplete}
          organization={selectedOrganization}
        />
      </TabPanel>

      <TabPanel
        id="org-settings-locations"
        index={1}
        value={value}
        sx={tabPanelSx}
      >
        <Locations
          organization={selectedOrganization}
          refetch_organization={refetchOrganization}
        />
      </TabPanel>

      <TabPanel
        id="org-settings-team-members"
        index={2}
        value={value}
        sx={tabPanelSx}
      >
        <TeamMembers organization={selectedOrganization} />
      </TabPanel>

      <TabPanel
        id="org-settings-delivery-vehicles"
        index={3}
        value={value}
        sx={tabPanelSx}
      >
        <DeliveryVehicles />
      </TabPanel>

      <TabPanel
        id="org-settings-data-sharing"
        index={4}
        value={value}
        sx={tabPanelSx}
      >
        <DataSharing switchToOrganizationTab={switchToOrganizationTab} />
      </TabPanel>
    </Box>
  )
}
