import type { ReactNode } from 'react'

import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

export type ViewTab = {
  label: string
  value: string
  icon?: ReactNode
  disabled?: boolean
}

interface TabPanelProps {
  tabs: ViewTab[]
  activeTab: string
  onTabChange: (tab: string) => void
}
export default function TabPanel({ tabs, activeTab, onTabChange }: TabPanelProps) {
  return (
    <Tabs value={activeTab} onChange={(_, tab) => onTabChange(tab)}>
      {tabs.map((tab) => (
        <Tab
          key={tab.value}
          label={<Box display="flex" alignItems="center" gap={1}>{tab.icon}<span>{tab.label}</span></Box>}
          value={tab.value}
          disabled={tab.disabled}
        />
      ))}
    </Tabs>
  )
}