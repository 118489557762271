import { useQuery } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createFileRoute } from '@tanstack/react-router'
import { useState } from 'react'
import { z } from 'zod'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'

import AddIcon from '@mui/icons-material/Add'
import { ArrowDownWideNarrow, ArrowUpNarrowWide } from 'lucide-react'

import CreatePlantListModal from '../../components/plant-lists/create-plant-list-modal.tsx'
import PlantListsTable from '../../components/plant-lists/plant-lists-table.tsx'
import PlantListsTableFilters from '../../components/plant-lists/plant-lists-table-filters.tsx'

import { getPlantLists } from '../../api/plant-list.ts'
import { FilterPlantListsFormSchema } from '../../lib/validation-schemas.ts'

export const Route = createFileRoute('/_authenticated/plant-lists/')({
  component: PlantLists,
})

function OrderBySelector({
  order_by,
  onChangeOrderBy,
}: {
  order_by: string
  onChangeOrderBy: (value: string) => void
}) {
  return (
    <Select value={order_by} onChange={(e) => onChangeOrderBy(e.target.value)}>
      <MenuItem value="created_at">Created At</MenuItem>
      <MenuItem value="name">List Name</MenuItem>
    </Select>
  )
}

function OrderByDirectionIndicator({
  direction,
  onToggleDirection,
}: {
  direction: 'asc' | 'desc'
  onToggleDirection: () => void
}) {
  const icon =
    direction === 'asc' ? (
      <ArrowUpNarrowWide size={24} />
    ) : (
      <ArrowDownWideNarrow size={24} />
    )

  return (
    <div className="w-6 hover:cursor-pointer" onClick={onToggleDirection}>
      {icon}
    </div>
  )
}

function PlantLists() {
  const [limit, setLimit] = useState(25)
  const [page, setPage] = useState(0)
  const [orderBy, setOrderBy] = useState<string>('created_at')
  const [orderByDirection, setOrderByDirection] = useState<'asc' | 'desc'>(
    'desc'
  )
  const [filters, setFilters] =
    useState<z.infer<typeof FilterPlantListsFormSchema>>()
  const [createPlantListModalOpen, setCreatePlantListModalOpen] =
    useState(false)

  const plantListsQuery = useQuery({
    queryKey: [
      'plant_lists',
      { limit, page, orderBy, orderByDirection, filters },
    ] as const,
    queryFn: ({ queryKey }) => {
      const [_, { limit, page, orderBy, orderByDirection, filters }] = queryKey
      return getPlantLists(limit, page, orderBy, orderByDirection, filters)
    },
    retry: 3,
  })
  const { isPending, error, data, isFetching } = plantListsQuery

  const handlePlantListCreated = async () => {
    await plantListsQuery.refetch()
    setCreatePlantListModalOpen(false)
    setPage(0)
  }

  const handleToggleOrderByDirection = () => {
    setOrderByDirection((direction) => (direction === 'asc' ? 'desc' : 'asc'))
  }

  const getFilteredPlantLists = async (
    filters: z.infer<typeof FilterPlantListsFormSchema>
  ) => {
    setPage(0)
    setFilters(filters)
  }

  const getUnfilteredPlantLists = async () => {
    setPage(0)
    setFilters(undefined)
  }

  return (
    <Box sx={{ p: 4 }}>
      <Box className="flex items-center justify-between">
        <Typography variant="h1">
          <Box className="flex items-center gap-4">
            Plant Lists
            {isFetching && <CircularProgress size={24} />}
          </Box>
        </Typography>
        <Button
          variant="contained"
          onClick={() => setCreatePlantListModalOpen(true)}
        >
          <AddIcon /> New Plant List
        </Button>
      </Box>
      <Box className="mt-8 flex">
        <PlantListsTableFilters
          applyFilters={getFilteredPlantLists}
          clearFilters={getUnfilteredPlantLists}
        />
        <div className="flex items-center">
          <div className="flex items-center space-x-2">
            <Typography>Order By:</Typography>
            <OrderBySelector order_by={orderBy} onChangeOrderBy={setOrderBy} />
          </div>
          <OrderByDirectionIndicator
            direction={orderByDirection}
            onToggleDirection={handleToggleOrderByDirection}
          />
        </div>
      </Box>
      <Box className="mt-4">
        <PlantListsTable
          plantLists={data || []}
          rowsPerPage={limit}
          page={page}
          loading={isPending}
          error={error}
          onPageChange={setPage}
          onRowsChange={setLimit}
        />
      </Box>
      {createPlantListModalOpen && (
        <CreatePlantListModal
          onPlantListCreated={handlePlantListCreated}
          onClose={() => setCreatePlantListModalOpen(false)}
        />
      )}
      <ReactQueryDevtools initialIsOpen={false} />
    </Box>
  )
}
