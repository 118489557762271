import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { DarkPrimaryButton } from '@/components/ui/base/buttons/buttons.tsx'
import { CirclePlus } from 'lucide-react'

export default function DeliveryVehicles() {
  return (
    <>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="tabHeader">Delivery Vehicles</Typography>
          <DarkPrimaryButton disabled>
            <Box display="flex" alignItems="center" gap={1}>
              <CirclePlus size="16" />
              <Typography variant="button">Add Vehicle</Typography>
            </Box>
          </DarkPrimaryButton>
        </Box>
      </Box>
    </>
  )
}
