import { useState, useRef, DragEvent, ChangeEvent } from 'react'
import { z, ZodError, ZodIssue } from 'zod'
import { useMutation } from '@tanstack/react-query'
import { createPlantList } from '@/api/plants.ts'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { ACCEPTED_FILE_EXTENSIONS, ACCEPTED_FILE_TYPES } from '@/constants.ts'
import { Checkbox, FormControlLabel } from '@mui/material'
import { ScrollText } from 'lucide-react'
import { DarkPrimaryButton, PrimaryCancelButton } from '../base/buttons/buttons'

const createPlantListFormSchema = z
  .object({
    name: z.string().min(1, { message: 'A plant list name is required' }),
    files: z.array(z.instanceof(File)),
    manualEntry: z.boolean(),
  })
  .refine((data) => data.files.length > 0 || data.manualEntry, {
    message: 'Either upload files or select manual entry',
    path: ['files', 'manualEntry'],
  })

interface AddPlantListModalProps {
  open: boolean
  onClose: () => void
  onPlantListCreated: (newPlantListId: string) => void
}
export default function AddPlantListModal({
  open,
  onClose,
  onPlantListCreated,
}: AddPlantListModalProps) {
  const { selectedOrganization } = useOrganization()
  const [name, setName] = useState('')
  const [files, setFiles] = useState<File[]>([])
  const [manualEntry, setManualEntry] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [validationError, setValidationError] = useState<ZodIssue | null>(null)

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(true)
  }

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)

    const droppedFiles = Array.from(e.dataTransfer.files)
    const allowedFiles = droppedFiles.filter((file) =>
      ACCEPTED_FILE_TYPES.includes(file.type)
    )

    setFiles((prev) => [...prev, ...allowedFiles])
  }

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files)
      name || setName(selectedFiles[0].name)
      setFiles((prev) => [...prev, ...selectedFiles])
      setManualEntry(false)
    }
  }

  const triggerFileInput = () => {
    fileInputRef.current?.click()
  }

  const createPlantListMutation = useMutation({
    mutationFn: createPlantList,
    onSuccess: async (newPlantList) => {
      setName('')
      setFiles([])
      setValidationError(null)
      onClose()
      onPlantListCreated(newPlantList.id)
    },
  })

  const handleSubmit = async () => {
    if (!selectedOrganization) {
      return
    }

    try {
      const trimmedName = name.trim()
      const validatedData = createPlantListFormSchema.parse({
        name: trimmedName,
        files,
        manualEntry,
      })
      const plantListRequest = {
        name: validatedData.name,
        organization: selectedOrganization.id,
        files: validatedData.files,
      }
      await createPlantListMutation.mutateAsync(plantListRequest)
    } catch (error) {
      if (error instanceof ZodError) {
        console.error('validation error', error)
        console.log('validation error message:', error.errors[0].message)
        setValidationError(error.errors[0])
      }
    }
  }

  const handleClose = () => {
    setName('')
    setFiles([])
    setManualEntry(false)
    setValidationError(null)
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      aria-labelledby="add-plant-list-modal-title"
      aria-describedby="add-plant-list-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 850,
          bgcolor: 'white',
          boxShadow: 24,
          p: 5,
          borderRadius: 2,
        }}
      >
        <Typography
          id="add-plant-list-modal-title"
          variant="modalHeader1"
          mb={5}
        >
          Add Plant List
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <TextField
            id="name"
            name="name"
            label="Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            error={!!validationError?.path.includes('name')}
            helperText={
              validationError?.path.includes('name') && validationError.message
            }
            fullWidth
            required
          />

          {files.length === 0 && (
            <>
              <Box>
                <Typography
                  id="add-plant-list-modal-description"
                  variant="body1"
                  gutterBottom
                >
                  Upload a Plant List and then assign plants to Jobs, RFPs,
                  Quotes, or Orders.
                </Typography>
                <Box
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  sx={{
                    border: '2px solid',
                    borderColor: isDragging
                      ? 'primary.main'
                      : validationError?.path.includes('files')
                        ? 'error.light'
                        : 'grey.400',
                    borderRadius: 2,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    cursor: 'pointer',
                    bgcolor: isDragging ? 'action.hover' : 'grey.200',
                    transition: 'all 0.2s ease',
                    color: validationError?.path.includes('files')
                      ? 'error.light'
                      : 'text.primary',
                  }}
                  onClick={triggerFileInput}
                >
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    multiple
                    accept={ACCEPTED_FILE_TYPES.join(',')}
                    onChange={handleFileSelect}
                  />
                  <ScrollText size={48} strokeWidth={1} />
                  <Typography variant="strongLg" mt={2} component="p">
                    Upload File(s)
                  </Typography>
                  <Typography variant="body2">
                    {ACCEPTED_FILE_EXTENSIONS.join(', ')}
                  </Typography>
                </Box>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={manualEntry}
                    onChange={(e) => setManualEntry(e.target.checked)}
                    color={
                      validationError?.path.includes('files')
                        ? 'error'
                        : 'default'
                    }
                  />
                }
                label="I don't have files, I'll add plants manually"
                sx={{
                  color: validationError?.path.includes('files')
                    ? 'error.light'
                    : 'text.primary',
                }}
              />
              {validationError?.path.includes('files') &&
                validationError?.path.includes('manualEntry') && (
                  <Typography variant="body1" color="error.light">
                    {validationError.message}
                  </Typography>
                )}
            </>
          )}

          {files.length > 0 && (
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 5 }}
            >
              {files.map((file, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 1,
                    border: '1px solid',
                    borderColor: 'grey.200',
                    borderRadius: 1,
                  }}
                >
                  <Typography>{file.name}</Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent="end" gap={1}>
          <PrimaryCancelButton onClick={handleClose}>
            Cancel &amp; Close
          </PrimaryCancelButton>
          <DarkPrimaryButton
            type="submit"
            onClick={handleSubmit}
            disabled={
              createPlantListMutation.isPending ||
              !name ||
              (files.length === 0 && !manualEntry)
            }
          >
            Continue
          </DarkPrimaryButton>
        </Box>
      </Box>
    </Modal>
  )
}
