import type { Auth0ContextInterface } from '@auth0/auth0-react'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import { CssBaseline } from '@mui/material'
import React, { Suspense } from 'react'

import Box from '@mui/material/Box'

import SideNav from '@/components/ui/side-nav.tsx'

const TanStackRouterDevtools = ['production', 'staging'].includes(
  import.meta.env.VITE_ENV
)
  ? () => null // Render nothing in production
  : React.lazy(() =>
      // Lazy load in development
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
        // For Embedded Mode
        // default: res.TanStackRouterDevtoolsPanel
      }))
    )

export const Route = createRootRouteWithContext<Auth0ContextInterface>()({
  component: Root,
})

function Root() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <SideNav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          margin: 'auto',
          height: '100vh',
        }}
      >
        <Outlet />
      </Box>
      <Suspense>
        <TanStackRouterDevtools position="top-right" />
      </Suspense>
    </Box>
  )
}
