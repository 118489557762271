import { useMutation } from '@tanstack/react-query'
import { type ChangeEvent, type FormEvent, useState } from 'react'
import { z } from 'zod'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { createOrganization } from '@/api/organization_management.ts'
import { trimValues } from '@/lib/utils.ts'
import { CreateOrganizationSchema } from '@/lib/validation-schemas.ts'

type NewOrganizationModalProps = {
  open: boolean
  onClose: () => void
  onOrganizationCreated: (newOrganizationId: string) => void
}
export default function NewOrganizationModal({
  open,
  onClose,
  onOrganizationCreated,
}: NewOrganizationModalProps) {
  const [formData, setFormData] = useState({ name: '' })
  const [validationError, setValidationError] = useState<string | null>('')

  const createOrgMutation = useMutation({
    mutationFn: createOrganization,
    onSuccess: async (newOrganization) => {
      setFormData({ name: '' })
      setValidationError(null)
      onClose()
      onOrganizationCreated(newOrganization.id)
    },
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (validationError) {
      setValidationError(null)
    }
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    const trimmedFormData = trimValues(formData)

    try {
      CreateOrganizationSchema.parse(trimmedFormData)
      setValidationError(null)
    } catch (error) {
      if (error instanceof z.ZodError) {
        setValidationError(error.errors[0].message)
      } else {
        setValidationError('An unknown error occurred')
      }
      return
    }

    await createOrgMutation.mutateAsync(formData)
  }

  const handleClose = () => {
    setFormData({ name: '' })
    setValidationError(null)
    onClose()
  }

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h2" gutterBottom>
          Create a New Organization
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              name="name"
              variant="outlined"
              label="Organization Name *"
              placeholder="Enter your organization's name"
              fullWidth
              value={formData.name}
              onChange={handleChange}
              error={Boolean(validationError)}
              helperText={validationError}
            />
            <Box display="flex" gap={2}>
              <Button type="submit" variant="contained" size="large">
                <Typography variant="button" textTransform="capitalize">
                  Create Organization
                </Typography>
              </Button>
              <Button
                variant="outlined"
                size="large"
                color="inherit"
                onClick={handleClose}
              >
                <Typography variant="button" textTransform="capitalize">
                  Cancel
                </Typography>
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  )
}
