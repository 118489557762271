import { createFileRoute } from '@tanstack/react-router'

import { useAuth0 } from '@auth0/auth0-react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

export const Route = createFileRoute('/_authenticated/auth-test')({
  component: AuthTest,
})

function AuthTest() {
  const { user, isAuthenticated, isLoading, logout } = useAuth0()

  if (!isAuthenticated || isLoading) {
    return <div>Loading...</div>
  }

  if (!isAuthenticated && !isLoading) {
    return <div>Not authenticated</div>
  }

  return (
    <Box>
      <h1>Authentication Test Page</h1>
      <p>
        If you are seeing this, then you are authenticated! Congratulations! 🎉
      </p>
      {user && <p>Hello, {user.given_name || user.email}!</p>}
      <p>
        Email: {user?.email}
        <br />
        First Name: {user?.given_name}
        <br />
        Last Name: {user?.family_name}
        <br />
        Nickname: {user?.nickname}
        <br />
        Avatar: <img src={user?.picture} alt="Avatar" />
        <br />
        User Data: {JSON.stringify(user, null, 2)}
      </p>
      <Button variant="text" onClick={() => logout()}>
        Log out
      </Button>
    </Box>
  )
}
