import type { Auth0UserExpanded } from '@/types.ts'
import { AUTH0_DOMAIN } from '@/constants.ts'
import axios from 'axios'

export async function getExpandedAuth0UserDetails(
  primaryUserId: string,
  primaryUserToken: string
) {
  const response = await axios.get(
    `https://${AUTH0_DOMAIN}/api/v2/users/${primaryUserId}`,
    {
      headers: {
        Authorization: `Bearer ${primaryUserToken}`,
      },
    }
  )
  return response.data as Auth0UserExpanded
}

export async function linkAccountToPrimaryAuth0User(
  secondaryUserIdToken: string,
  primaryUserId: string,
  primaryUserToken: string
) {
  return await axios.post(
    `https://${AUTH0_DOMAIN}/api/v2/users/${primaryUserId}/identities`,
    {
      link_with: secondaryUserIdToken,
    },
    {
      headers: {
        Authorization: `Bearer ${primaryUserToken}`,
        'Content-Type': 'application/json',
      },
    }
  )
}

export async function unlinkAccountFromPrimaryAuth0User(
  provider: string,
  secondaryUserId: string,
  primaryUserId: string,
  primaryUserToken: string
) {
  return await axios.delete(
    `https://${AUTH0_DOMAIN}/api/v2/users/${primaryUserId}/identities/${provider}/${secondaryUserId}`,
    {
      headers: {
        Authorization: `Bearer ${primaryUserToken}`,
      },
    }
  )
}
