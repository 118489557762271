import { useQuery } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
import {
  type ChangeEvent,
  type SyntheticEvent,
  useState,
  useCallback,
} from 'react'

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { searchOrganizationContactsByName } from '@/api/contacts.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import type {
  ContactDetails,
  ContactEmail,
  ContactPhone,
  MinimalOrgContactDetails,
} from '@/types.ts'
import { initialFormEmail, initialFormPhone } from '@/seed_form_data.ts'
import { countryFormattedPhoneNumber, removeItemByIndex } from '@/lib/utils.ts'

interface OrganizationOptionType {
  inputValue?: string
  organization_name: string
  id?: string
}

const filter = createFilterOptions<OrganizationOptionType>()

type ContactPersonFormProps = {
  contactDetails: ContactDetails
  onContactDetailsChange: (
    details: ContactDetails | ContactPhone[] | ContactEmail[]
  ) => void
}

export default function ContactPersonForm({
  contactDetails,
  onContactDetailsChange,
}: ContactPersonFormProps) {
  const { selectedOrganization } = useOrganization()
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const initialFormOrgContactMinimal: MinimalOrgContactDetails = {
    organization_name: '',
    organization: selectedOrganization,
    type: 'organization',
    id: null,
  }
  const [orgContactDetails, setOrgContactDetails] = useState(
    contactDetails.organization_contact &&
      contactDetails.organization_contact.length
      ? contactDetails.organization_contact
      : [initialFormOrgContactMinimal]
  )
  const [phoneNumbers, setPhoneNumbers] = useState(
    contactDetails.phone && contactDetails.phone.length
      ? contactDetails.phone
      : [initialFormPhone]
  )
  const [emailAddresses, setEmailAddresses] = useState(
    contactDetails.email && contactDetails.email.length
      ? contactDetails.email
      : [initialFormEmail]
  )

  const { data: searchResults = { items: [] }, isLoading } = useQuery({
    queryKey: ['organization_contacts', debouncedSearchTerm],
    queryFn: () =>
      searchOrganizationContactsByName(
        debouncedSearchTerm,
        selectedOrganization
      ),
    enabled: debouncedSearchTerm.length >= 3,
  })

  const handleSearchInputChange = (_: SyntheticEvent, value: string) => {
    setSearchTerm(value)
  }

  const handleOrganizationChange = useCallback(
    (
      index: number,
      _: SyntheticEvent,
      value: string | OrganizationOptionType | null
    ) => {
      let updateOrganizationContacts = [
        ...(contactDetails.organization_contact || [
          initialFormOrgContactMinimal,
        ]),
      ]
      if (typeof value === 'string') {
        updateOrganizationContacts[index] = {
          ...initialFormOrgContactMinimal,
          organization_name: value,
        }
      } else if (value?.inputValue) {
        updateOrganizationContacts[index] = {
          ...initialFormOrgContactMinimal,
          organization_name: value.inputValue,
        }
      } else if (value) {
        updateOrganizationContacts[index] = {
          ...initialFormOrgContactMinimal,
          organization_name: value.organization_name || '',
          id: value.id!,
        }
      } else {
        updateOrganizationContacts = removeItemByIndex(
          updateOrganizationContacts,
          index
        ) as MinimalOrgContactDetails[]
        setOrgContactDetails(
          updateOrganizationContacts.length
            ? (updateOrganizationContacts as MinimalOrgContactDetails[])
            : [initialFormOrgContactMinimal]
        )
      }
      onContactDetailsChange({
        ...contactDetails,
        organization_contact:
          updateOrganizationContacts as MinimalOrgContactDetails[],
      })
    },
    [contactDetails, selectedOrganization, onContactDetailsChange]
  )

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number = 0
    ) => {
      const { name, value } = event.target

      const updateContactDetail = (
        field: 'phone' | 'email',
        subField: 'number' | 'address' | 'extension' | 'contact_type'
      ) => {
        const currentDetails = field === 'phone' ? phoneNumbers : emailAddresses
        const updatedDetails = [...currentDetails]
        const currentDetail = updatedDetails[index] || {}
        let parsed_value = value

        if (field === 'phone' && subField === 'number') {
          parsed_value = countryFormattedPhoneNumber(value)
        }

        updatedDetails[index] = {
          ...currentDetail,
          [subField]:
            subField === 'contact_type'
              ? (parsed_value as 'work' | 'mobile' | 'other')
              : parsed_value,
        }

        if (field === 'phone') {
          setPhoneNumbers(updatedDetails as ContactPhone[])
        } else {
          setEmailAddresses(updatedDetails as ContactEmail[])
        }

        return {
          [field]: updatedDetails,
        }
      }

      const inputHandlers: Record<string, () => ContactDetails> = {
        phone_number: () => updateContactDetail('phone', 'number'),
        phone_extension: () => updateContactDetail('phone', 'extension'),
        phone_type: () => updateContactDetail('phone', 'contact_type'),
        email_address: () => updateContactDetail('email', 'address'),
        email_type: () => updateContactDetail('email', 'contact_type'),
        default: () => ({ ...contactDetails, [name]: value }),
      }

      const handler = inputHandlers[name] || inputHandlers.default
      onContactDetailsChange(handler())
    },
    [contactDetails, onContactDetailsChange]
  )

  const addPhone = () => {
    const updatedPhone = [...(contactDetails.phone || []), initialFormPhone]
    setPhoneNumbers(updatedPhone)
    onContactDetailsChange(updatedPhone)
  }

  const addEmail = () => {
    const updatedEmail = [...(contactDetails.email || []), initialFormEmail]
    setEmailAddresses(updatedEmail)
    onContactDetailsChange(updatedEmail)
  }

  const addOrganization = () => {
    const updateOrganizationContacts = [
      ...(orgContactDetails || []),
      initialFormOrgContactMinimal,
    ]
    setOrgContactDetails(
      updateOrganizationContacts as MinimalOrgContactDetails[]
    )
    onContactDetailsChange({
      ...contactDetails,
      organization_contact:
        updateOrganizationContacts as MinimalOrgContactDetails[],
    })
  }

  return (
    <Box>
      {orgContactDetails.map((org, index) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          key={index}
          sx={{ mb: 2 }}
        >
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              loading={isLoading}
              onInputChange={handleSearchInputChange}
              onChange={(event, value) =>
                handleOrganizationChange(index, event, value)
              }
              filterOptions={(options, params) => {
                const filtered = filter(options, params)
                const { inputValue } = params
                const isExisting = options.some(
                  (option) => inputValue === option.organization_name
                )
                if (
                  inputValue !== '' &&
                  inputValue.trim().length >= 3 &&
                  !isExisting
                ) {
                  filtered.push({
                    inputValue,
                    organization_name: `Add new organization contact: "${inputValue}"`,
                  })
                }
                return filtered
              }}
              selectOnFocus
              handleHomeEndKeys
              value={org.organization_name || ''}
              options={searchResults.items as OrganizationOptionType[]}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option
                }
                if (option.inputValue) {
                  return option.inputValue
                }
                return option.organization_name
              }}
              renderOption={(props, option) => {
                const { ...optionProps } = props
                return <li {...optionProps}>{option.organization_name}</li>
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="organization_name"
                  label={`Organization Name ${index + 1}`}
                  placeholder="Search organization name..."
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      ))}
      <Button
        variant="text"
        color="inherit"
        size="small"
        sx={{ mb: 2 }}
        onClick={addOrganization}
      >
        <Typography variant="button" textTransform="capitalize">
          + Add Another Organization
        </Typography>
      </Button>

      <Typography variant="subtitle1" gutterBottom>
        Name
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            name="first_name"
            label="First Name"
            placeholder="First Name"
            fullWidth
            required
            value={contactDetails.first_name || ''}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            name="last_name"
            label="Last Name"
            placeholder="Last Name"
            fullWidth
            required
            value={contactDetails.last_name || ''}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle1" gutterBottom>
        Contact Details{' '}
        <Typography variant="caption">phone or email required</Typography>
      </Typography>

      {phoneNumbers.map((phone, index) => (
        <Grid container spacing={2} mb={1} key={index}>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              name="phone_number"
              label="Phone"
              type="tel"
              placeholder="+ (---) --- ----"
              fullWidth
              value={phone.number}
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              name="phone_extension"
              label="Extension"
              placeholder="-"
              fullWidth
              value={phone.extension || ''}
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              name="phone_type"
              label="Type"
              fullWidth
              select
              value={phone.contact_type || ''}
              onChange={(event) => handleInputChange(event, index)}
            >
              <MenuItem value="work">Work</MenuItem>
              <MenuItem value="mobile">Mobile</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="text"
        color="inherit"
        size="small"
        sx={{ mb: 2 }}
        onClick={addPhone}
      >
        <Typography variant="button" textTransform="capitalize">
          + Add Another Phone
        </Typography>
      </Button>

      {emailAddresses.map((email, index) => (
        <Grid container spacing={2} mb={1} key={index}>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              name="email_address"
              label="Email"
              type="email"
              placeholder="info@acme.co"
              fullWidth
              value={email.address}
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              name="email_type"
              label="Type"
              fullWidth
              select
              value={email.contact_type || ''}
              onChange={(event) => handleInputChange(event, index)}
            >
              <MenuItem value="work">Work</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="text"
        color="inherit"
        size="small"
        sx={{ mb: 2 }}
        onClick={addEmail}
      >
        <Typography variant="button" textTransform="capitalize">
          + Add Another Email
        </Typography>
      </Button>
    </Box>
  )
}
