import type { User, UserInfo } from '@/types.ts'

import api from './api.ts'

export async function getUserInfo() {
  const response = await api.get('/v1/auth0_user/me')
  return response.data as UserInfo
}

export async function updateUser(user: User | Partial<User>) {
  const response = await api.patch(`/v1/users/${user.id}`, user, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data as User
}
