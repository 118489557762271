import { useContext } from 'react'
import { UserInfoContext } from '@/contexts/UserInfoContext.tsx'

export const useUserInfo = () => {
  const context = useContext(UserInfoContext)
  if (context === undefined) {
    throw new Error('useUserInfo must be used within an UserInfoProvider')
  }
  return context
}
