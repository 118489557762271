import { styled } from '@mui/material/styles'
import { Link } from '@tanstack/react-router'
import type { ReactNode } from 'react'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { ArrowLeft } from 'lucide-react'

import GlobalNewButton from '@/components/ui/global-new-button.tsx'

const StyledToolbar = styled(Toolbar)(() => ({}))

interface PageLayoutProps {
  title: string
  children: ReactNode
  icon?: ReactNode
  backRoute?: { to: string; label: string }
  globalNewButton?: true
}
export default function PageLayout({
  title,
  icon,
  children,
  backRoute,
  globalNewButton,
}: PageLayoutProps) {
  return (
    <Box display="flex" flexDirection="column" height="100%" p={2}>
      {backRoute && (
        <Box display="flex" alignItems="center" gap={1}>
          <ArrowLeft size={22} />
          <Link
            to={backRoute.to}
            className="max-w-fit border-b-[1px] border-b-black font-bold"
          >
            {backRoute.label}
          </Link>
        </Box>
      )}
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: 'white',
        }}
      >
        <StyledToolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px !important',
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            {icon}
            <Typography variant="h1">{title}</Typography>
          </Box>

          {globalNewButton && <GlobalNewButton />}
        </StyledToolbar>
      </AppBar>

      <Box flex={1} overflow="auto">
        {children}
      </Box>
    </Box>
  )
}
