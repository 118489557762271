import { useDebounce } from '@uidotdev/usehooks'
import { useEffect, useState } from 'react'
import type { ElasticsearchResponse } from '@/types'
import { useQuery } from '@tanstack/react-query'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { Search } from 'lucide-react'

export interface SearchBarProps<T> {
  placeholder: string
  indexName: string
  onSearchResults?: (response: ElasticsearchResponse<T>) => void
  debounceMs?: number
  minimumSearchLength?: number
}
export default function SearchBar<T>({
  placeholder,
  indexName,
  onSearchResults,
  debounceMs = 300,
  minimumSearchLength = 3,
}: SearchBarProps<T>) {
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, debounceMs)

  const { data: searchResponse, isLoading: isSearching } = useQuery({
    queryKey: ['search', indexName, debouncedSearchValue],
    queryFn: () => {
      // TODO: Implement search
      return {
        page: 1,
        limit: 10,
        last_page: 1,
        results_count: 0,
        results: [],
        items: [],
      } as ElasticsearchResponse<T>
    },
    enabled: debouncedSearchValue.length > minimumSearchLength,
    staleTime: 1000 * 60 * 5, // 5 minutes
  })

  useEffect(() => {
    if (searchResponse) {
      onSearchResults?.(searchResponse)
    }
  }, [searchResponse])

  return (
    <TextField
      fullWidth
      placeholder={placeholder}
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      InputProps={{
        startAdornment: <Search className="mr-1" />,
        endAdornment: isSearching && <CircularProgress size={16} />,
      }}
      variant="outlined"
      size="small"
      sx={{ maxWidth: 400 }}
    />
  )
}
