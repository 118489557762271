import { type SyntheticEvent, useState } from 'react'

import Box from '@mui/material/Box'

import { BellIcon, Building2Icon, CircleUser, Settings } from 'lucide-react'

import a11yProps from '@/components/ui/a11y-props.ts'
import MyProfileTab from '@/components/account-preferences/my-profile-tab.tsx'
import AccountTab from '@/components/account-preferences/account-tab.tsx'
import AccountOrganizationsTab from '@/components/account-preferences/organizations-tab.tsx'
import NotificationsSettings from '@/components/account-preferences/notifications-tab.tsx'
import TabPanel from '@/components/ui/base/tabs/tab-panel.tsx'
import TabLabel from '@/components/ui/base/tabs/tab-label'
import {
  StyledVerticalTab,
  StyledVerticalTabs,
} from '@/components/ui/base/tabs/styled-tabs.tsx'
import { type SxProps } from '@mui/material'
import Divider from '@mui/material/Divider'

type AccountOrganizationsTabsProps = {
  closeModal: () => void
}

export default function AccountPreferencesTabs({
  closeModal,
}: AccountOrganizationsTabsProps) {
  const [value, setValue] = useState(0)

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const tabPanelSx: SxProps = {
    py: 5,
    pr: 5,
  }

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        display: 'flex',
        height: '100%',
        overflowY: 'hidden',
      }}
    >
      <StyledVerticalTabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="account-preferences tabs"
      >
        {[
          { label: 'My Profile', icon: <CircleUser /> },
          { label: 'Account', icon: <Settings /> },
          { label: 'Organizations', icon: <Building2Icon /> },
          { label: 'Notifications', icon: <BellIcon /> },
        ].map((tab, index) => (
          <StyledVerticalTab
            key={index}
            label={<TabLabel label={tab.label} icon={tab.icon} />}
            sx={{
              color: 'text.primary',
              textAlign: 'left',
              textTransform: 'none',
              alignItems: 'flex-start',
            }}
            {...a11yProps(index, 'account-preferences')}
          />
        ))}
      </StyledVerticalTabs>

      <Divider orientation="vertical" flexItem sx={{ m: 5 }} />

      <TabPanel
        id="account-preferences-profile"
        index={0}
        value={value}
        sx={tabPanelSx}
      >
        <MyProfileTab />
      </TabPanel>

      <TabPanel
        id="account-preferences-account"
        index={1}
        value={value}
        sx={tabPanelSx}
      >
        <AccountTab />
      </TabPanel>

      <TabPanel
        id="account-preferences-organizations"
        index={2}
        value={value}
        sx={tabPanelSx}
      >
        <AccountOrganizationsTab closeModal={closeModal} />
      </TabPanel>

      <TabPanel
        id="account-preferences-notifications"
        index={3}
        value={value}
        sx={tabPanelSx}
      >
        <NotificationsSettings />
      </TabPanel>
    </Box>
  )
}
