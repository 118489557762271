import { styled } from '@mui/material/styles'
import { useQuery } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'
import { format } from 'date-fns'
// import { usePubNub } from 'pubnub-react'
import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Skeleton from '@mui/material/Skeleton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import ErrorIcon from '@mui/icons-material/Error'
import CheckIcon from '@mui/icons-material/Check'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import { getFileProcessingForPlantLists } from '@/api/ai-processed-plant-list-file.ts'
import { usePubNub } from '@/contexts/PubNubContext'
import type { AIProcessingData, PlantList } from '@/types.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightRegular,
    border: '1px solid #dadde9',
  },
}))

type PlantListsTableProps = {
  plantLists: PlantList[]
  rowsPerPage: number
  page: number
  loading: boolean
  error: Error | null
  onPageChange: (page: number) => void
  onRowsChange: (rows: number) => void
}
export default function PlantListsTable({
  plantLists,
  rowsPerPage,
  page,
  loading,
  error,
  onPageChange,
  onRowsChange,
}: PlantListsTableProps) {
  const { subscribeToChannels, unsubscribeFromChannels } = usePubNub()
  const { selectedOrganization } = useOrganization()
  const {
    error: fileProcessingError,
    data: fileProcessingData,
    isFetching: isFileProcessingFetching,
    refetch: refetchFileProcessing,
  } = useQuery({
    queryKey: ['file_processing', plantLists.map((list) => list.id)] as const,
    queryFn: ({ queryKey }) => {
      const [_, plantListIds] = queryKey
      if (plantListIds.length === 0) {
        return []
      }
      return getFileProcessingForPlantLists(
        selectedOrganization?.id,
        plantListIds
      )
    },
    retry: 3,
  })
  const [processingDataByPlantList, setProcessingDataByPlantList] = useState<{
    [key: string]: AIProcessingData[]
  }>({})
  const [selectedPage, setSelectedPage] = useState(page)
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(rowsPerPage)

  useEffect(() => {
    setSelectedPage(page)
  }, [page])

  useEffect(() => {
    // Group processing data by parent plant list
    const groupedData = (fileProcessingData || []).reduce(
      (acc: { [key: string]: AIProcessingData[] }, data: AIProcessingData) => {
        const plantListId = data.parent_plant_list_id
        if (!acc[plantListId]) {
          acc[plantListId] = []
        }
        acc[plantListId].push(data)
        return acc
      },
      {} as { [key: string]: AIProcessingData[] }
    )
    setProcessingDataByPlantList(groupedData)
  }, [fileProcessingData])

  useEffect(() => {
    const channels = fileProcessingData
      ?.filter((data) => !data.processing_completed && !data.breaking_error)
      .map((data) => data.pubsub_channel)

    if (!channels || channels.length === 0) {
      return
    }

    const listener = async (messageEvent: any) => {
      const { channel_id, model } = messageEvent.message

      if (model.deleted_at) {
        refetchFileProcessing()
        return
      }

      setProcessingDataByPlantList((prevData) => {
        const updatedData = { ...prevData }
        Object.values(updatedData).forEach((files) => {
          const fileIndex = files.findIndex(
            (file) => file.pubsub_channel === channel_id
          )
          if (fileIndex !== -1) {
            files[fileIndex] = {
              ...files[fileIndex],
              processing_errors: model.processing_errors,
              processing_began: model.processing_began,
              processing_completed: model.processing_completed,
              breaking_error: model.breaking_error,
            }
          }
        })
        return updatedData
      })
    }

    subscribeToChannels(channels, 'plantList', listener)

    return () => {
      unsubscribeFromChannels(channels, 'plantList')
    }
  }, [fileProcessingData, refetchFileProcessing])

  const handleRowsPerPageChange = (event: SelectChangeEvent) => {
    setSelectedRowsPerPage(parseInt(event.target.value, 10))
    setSelectedPage(0)
    onRowsChange(parseInt(event.target.value, 10))
    onPageChange(0)
  }

  const handlePreviousClick = () => {
    const newPage = Math.max(selectedPage - 1, 0)
    setSelectedPage(newPage)
    onPageChange(newPage)
  }

  const handleNextClick = () => {
    const newPage = selectedPage + 1
    setSelectedPage(newPage)
    onPageChange(newPage)
  }

  const getPlantListStatus = (plantListId: string) => {
    const processingData = processingDataByPlantList[plantListId]

    if (fileProcessingError) {
      return (
        <div className="flex justify-end text-red-700">
          <HtmlTooltip
            arrow
            title={
              <p className="text-center">
                Error: {fileProcessingError.message}
              </p>
            }
            enterDelay={0}
          >
            <div className="flex items-center space-x-2 hover:cursor-default">
              <ErrorIcon sx={{ fontSize: 18 }} />
              <span>Error fetching status</span>
            </div>
          </HtmlTooltip>
        </div>
      )
    }

    if (isFileProcessingFetching) {
      return <Skeleton />
    }

    if (!processingData) {
      return (
        <div className="flex justify-end text-zinc-500">
          <div className="flex items-center space-x-2">
            <span>Unknown</span>
            <HtmlTooltip
              arrow
              title={
                <p className="text-center">
                  Please refresh the page to get the latest status of this plant
                  list.
                </p>
              }
              enterDelay={0}
            >
              <HelpOutlineIcon sx={{ fontSize: 18 }} />
            </HtmlTooltip>
          </div>
        </div>
      )
    }

    if (processingData.some((data) => data.breaking_error)) {
      const breakingErrors = processingData
        .filter((data) => data.breaking_error)
        .map((data) => (
          <div
            key={data._id}
            className="mb-4 max-h-[300px] overflow-y-scroll text-red-700"
          >
            <p>Error Type: {data.breaking_error?.error_type}</p>
            <p>
              Errored on retry iteration:{' '}
              {data.breaking_error?.retry_iteration || 0}
            </p>
            <p>Error Message:</p>
            <p className="pl-3">{data.breaking_error?.message}</p>
          </div>
        ))
      return (
        <div className="flex justify-end text-red-700">
          <HtmlTooltip arrow title={breakingErrors} enterDelay={0}>
            <div className="flex items-center space-x-2 hover:cursor-default">
              <ErrorIcon sx={{ fontSize: 18 }} />
              <span>Error</span>
            </div>
          </HtmlTooltip>
        </div>
      )
    }
    if (processingData.every((data) => data.processing_completed)) {
      return (
        <div className="flex justify-end text-green-700">
          <div className="flex items-center space-x-2">
            <CheckIcon sx={{ fontSize: 18 }} />
            <span>Ready</span>
          </div>
        </div>
      )
    }
    return (
      <div className="flex items-center justify-end text-zinc-700">
        <CircularProgress size={14} className="mr-2" color="inherit" />{' '}
        Processing
      </div>
    )
  }

  let tableRows = [
    <TableRow key={'no-plant-lists-found'}>
      <TableCell align="center" colSpan={3}>
        No Plant Lists Found.
      </TableCell>
    </TableRow>,
  ]

  if (error) {
    tableRows = [
      <TableRow key={'error-occurred'}>
        <TableCell align="center" colSpan={3}>
          <span className="text-red-600">Error: {error.message}</span>
        </TableCell>
      </TableRow>,
    ]
  }

  if (loading) {
    tableRows = Array.from({ length: rowsPerPage }, (_, index) => (
      <TableRow key={index}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    ))
  }

  if (plantLists.length > 0 && !loading && !error) {
    tableRows = plantLists.map((plantList, index) => (
      <TableRow key={plantList.id}>
        <TableCell align="left">
          <Link
            to={`/plant-lists/${plantList.id}`}
            style={{ textDecoration: 'none' }}
          >
            <Typography
              variant="body1"
              data-testid={`plant-list-table-row-${index}-name`}
            >
              {plantList.name}
            </Typography>
          </Link>
        </TableCell>
        <TableCell align="center">
          {plantList.created_by?.email || 'N/A'}
        </TableCell>
        <TableCell align="center">
          {format(
            plantList.created_at.endsWith('Z')
              ? plantList.created_at
              : plantList.created_at + 'Z',
            'PPP, ppp'
          )}
        </TableCell>
        <TableCell align="right">{getPlantListStatus(plantList.id)}</TableCell>
      </TableRow>
    ))
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer className="max-h-[60vh] overflow-y-scroll">
          <Table stickyHeader>
            <TableHead
              sx={{
                '& .MuiTableCell-head': {
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  backdropFilter: 'blur(4px)',
                  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="center">Created By</TableCell>
                <TableCell align="center">Created At</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-between border-t-[1px] p-4">
          <div className="flex items-center space-x-2">
            <Typography>Rows per page:</Typography>
            <Select
              value={selectedRowsPerPage.toString()}
              onChange={handleRowsPerPageChange}
              sx={{ minWidth: 100 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </div>
          <div className="flex items-center">
            <Button
              variant="text"
              onClick={handlePreviousClick}
              disabled={selectedPage === 0}
            >
              <ChevronLeft />
            </Button>
            <Typography>{selectedPage + 1}</Typography>
            <Button
              variant="text"
              onClick={handleNextClick}
              disabled={plantLists?.length !== selectedRowsPerPage}
            >
              <ChevronRight />
            </Button>
          </div>
        </div>
      </Paper>
    </Box>
  )
}
