import Alert from '@mui/material/Alert'
import { ToastNotificationMessage } from '@/types.ts'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import Box from '@mui/material/Box'
import Grow from '@mui/material/Grow'
import { AlertTitle } from '@mui/material'

type ToastNotificationsProps = {
  notifications: ToastNotificationMessage[]
}

const ToastNotifications = ({ notifications }: ToastNotificationsProps) => {
  const { removeToastNotification } = useToastNotifications()

  const renderNotification = (notification: ToastNotificationMessage) => {
    /*
     * This function is used to render the toast notification.
     * It uses the notification object to determine the variant and severity of the notification.
     * The notification object is passed as a prop to the ToastNotifications component.
     * The function returns an Alert component with the appropriate variant and severity.
     * Default background color is transparent for variant 'outlined', changed to white for better visual.
     */
    const variant = notification.variant || 'outlined'
    switch (notification.severity) {
      case 'success': // expand when styling is available
      case 'info':
      case 'warning':
      case 'error':
      default:
        return (
          <Grow in={true} key={notification.id}>
            <Alert
              variant={variant}
              onClose={() => removeToastNotification(notification.id as string)}
              severity={notification.severity}
              sx={{
                width: '100%',
                minWidth: '300px',
                backgroundColor: `${notification.variant === 'filled' ? '' : 'white'}`,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              {notification.title && (
                <AlertTitle>{notification.title}</AlertTitle>
              )}
              {notification.message}
            </Alert>
          </Grow>
        )
    }
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 24,
        right: 24,
        display: 'flex',
        flexDirection: 'column-reverse', // Stack from bottom up
        gap: 2,
        zIndex: 2000,
      }}
    >
      {notifications.map((notification) => renderNotification(notification))}
    </Box>
  )
}
export default ToastNotifications
