import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { styled } from '@mui/material/styles'
import { useQuery } from '@tanstack/react-query'
import { usePubNub } from '@/contexts/PubNubContext'
import {
  Fragment,
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import Send from '@mui/icons-material/Send'

import FilterBar from './filter-bar.tsx'
import CreateRFPModal from './create-rfp-modal.tsx'
import PlantListEntryForm from './plant-list-entry-form.tsx'
import SortablePlantItem from './sortable-plant-item.tsx'

import { DEFAULT_PLANT_LIST_ENTRY } from '@/constants.ts'
import { generateObjectId } from '@/lib/utils.ts'
import { getOptions, getPlantListById } from '@/api/plant-list.ts'
import type { AIProcessingData, PlantList, PlantListEntry } from '@/types.ts'

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: '60vh',
  overflow: 'auto',
  position: 'relative',
  '& .MuiTableFooter-root': {
    position: 'sticky',
    bottom: 0,
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 -1px 2px rgba(0, 0, 0, 0.1)',
  },
}))

type PlantListProps = {
  plantList: PlantList
  fileProcessingData: AIProcessingData[]
  onUpdate: (plants: PlantListEntry[]) => void
  onRFPCreated: () => void
}

export default function PlantListTable({
  plantList,
  fileProcessingData,
  onUpdate,
  onRFPCreated,
}: PlantListProps) {
  const { subscribeToChannels, unsubscribeFromChannels } = usePubNub()
  const [localPlants, setLocalPlants] = useState<PlantListEntry[]>(
    plantList.entries
  )
  const [selectedPlants, setSelectedPlants] = useState<Set<string>>(new Set())
  const [isCreateRFPModalOpen, setIsCreateRFPModalOpen] = useState(false)
  const [expandedEntryId, setExpandedEntryId] = useState<string | null>(null)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [fileFilter, setFileFilter] = useState('')
  const [rfpFilter, setRfpFilter] = useState('')
  const [commonNameFilter, setCommonNameFilter] = useState('')
  const [scientificNameFilter, setScientificNameFilter] = useState('')

  const tableRef = useRef<HTMLTableElement>(null)
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const scrollToElement = useCallback((elementId: string) => {
    const element = document.getElementById(elementId)
    const container = tableContainerRef.current
    const header = tableRef.current?.querySelector('thead')

    if (element && container && header) {
      const headerHeight = header.getBoundingClientRect().height
      const elementPosition = element.getBoundingClientRect().top
      const containerPosition = container.getBoundingClientRect().top
      const offsetPosition =
        elementPosition - containerPosition - headerHeight - 10 // 10px extra padding

      container.scrollTo({
        top: container.scrollTop + offsetPosition,
        behavior: 'smooth',
      })
    }
  }, [])

  const { data: optionsEnums } = useQuery({
    queryKey: ['optionEnums'],
    queryFn: getOptions,
  })

  useEffect(() => {
    setLocalPlants(plantList.entries)
    setHasUnsavedChanges(false)
  }, [plantList])

  useEffect(() => {
    const channels = fileProcessingData
      ?.filter((data: AIProcessingData) => !data.processing_completed)
      .map((data: AIProcessingData) => data.pubsub_channel)

    if (!channels || channels.length === 0) {
      return
    }

    const listener = async (messageEvent: any) => {
      const { channel_id, model } = messageEvent.message
      // find the file that matches the channel_id
      const fileIndex = fileProcessingData.findIndex(
        (data) => data.pubsub_channel === channel_id
      )
      if (fileIndex === -1) {
        return
      }
      const updatedFileProcessing = [...fileProcessingData]
      updatedFileProcessing[fileIndex] = {
        ...updatedFileProcessing[fileIndex],
        processing_errors: model.processing_errors,
        processing_completed: model.processing_completed,
      }

      // if model.processing_completed is true, refetch the plant list
      if (model.processing_completed) {
        const plantListData = await getPlantListById(plantList.id)
        if (!plantListData || !plantListData.entries.length) {
          return
        }

        // get any entries from current plant list that are new
        const newEntries = localPlants.filter((entry) => entry.is_new)

        if (newEntries.length > 0) {
          plantListData.entries[
            plantListData.entries.length - 1
          ].parent_of_order = newEntries[0].id
          // update the order of the new entries
          newEntries.forEach((entry, index) => {
            entry.parent_of_order =
              index === newEntries.length - 1 ? null : newEntries[index + 1].id
          })
        }
        // add the new entries to the plant list
        const updatedEntries = [...plantListData.entries, ...newEntries]
        setLocalPlants(updatedEntries)
      }
    }

    subscribeToChannels(channels, 'plantList', listener)

    return () => {
      unsubscribeFromChannels(channels, 'plantList')
    }
  }, [fileProcessingData, plantList.id, plantList.entries, localPlants])

  const deletedFileIds = useMemo(() => {
    return new Set(
      plantList.files.filter((file) => file.deleted_at).map((file) => file.id)
    )
  }, [plantList.files])

  const filteredPlants = useMemo(() => {
    return localPlants.filter((plant) => {
      if (plant.deleted_at) return false
      if (plant.file_id && deletedFileIds.has(plant.file_id)) return false
      if (fileFilter && plant.file_id !== fileFilter) return false
      if (rfpFilter && !plant.rfp_id.includes(Number(rfpFilter))) return false
      if (
        commonNameFilter &&
        !plant.common_name
          ?.toLowerCase()
          .includes(commonNameFilter.toLowerCase())
      )
        return false
      if (
        scientificNameFilter &&
        !plant.scientific_name
          ?.toLowerCase()
          .includes(scientificNameFilter.toLowerCase())
      )
        return false
      return true
    })
  }, [
    commonNameFilter,
    deletedFileIds,
    fileFilter,
    localPlants,
    rfpFilter,
    scientificNameFilter,
  ])

  const rfpIds = useMemo(() => {
    const ids = new Set<string>()
    localPlants.forEach((plant) =>
      plant.rfp_id.forEach((id) => ids.add(String(id)))
    )
    return Array.from(ids)
  }, [localPlants])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over, delta } = event
    const movedDown = delta.y > 0

    if (active.id === over?.id) {
      return
    }

    if (!over) {
      return
    }

    setLocalPlants((plants) => {
      const oldIndex = plants.findIndex((plant) => plant.id === active.id)
      const parentOfActive = plants.find(
        (plant) => plant.parent_of_order === active.id
      )
      const activePlant = plants[oldIndex]
      const newIndex = plants.findIndex((plant) => plant.id === over.id)
      const parentOfOver = plants.find(
        (plant) => plant.parent_of_order === over.id
      )
      const overPlant = plants[newIndex]

      if (parentOfActive) {
        parentOfActive.parent_of_order = activePlant.parent_of_order
      }
      if (parentOfOver && !movedDown) {
        parentOfOver.parent_of_order = activePlant.id
      }
      if (movedDown) {
        activePlant.parent_of_order = overPlant.parent_of_order
        overPlant.parent_of_order = active.id.toString()
      } else {
        activePlant.parent_of_order = over.id.toString()
      }

      const newPlants = arrayMove(plants, oldIndex, newIndex)
      setHasUnsavedChanges(true)
      return newPlants
    })
  }

  const toggleSelect = (plantId: string) => {
    const newSelectedPlants = new Set(selectedPlants)
    if (newSelectedPlants.has(plantId)) {
      newSelectedPlants.delete(plantId)
    } else {
      newSelectedPlants.add(plantId)
    }
    setSelectedPlants(newSelectedPlants)
  }

  const handleDelete = () => {
    setLocalPlants((plants) =>
      plants.map((plant) => {
        if (selectedPlants.has(plant.id)) {
          return { ...plant, deleted_at: new Date().toISOString() }
        }
        return plant
      })
    )
    setHasUnsavedChanges(true)
    setSelectedPlants(new Set())
  }

  const handleAddPlant = () => {
    const newId = generateObjectId()
    const newPlant: PlantListEntry = {
      ...DEFAULT_PLANT_LIST_ENTRY,
      id: newId,
      is_new: true,
    }
    setLocalPlants((prevPlants) => {
      const updatedPlants = prevPlants.map((plant) =>
        plant.parent_of_order === null
          ? { ...plant, parent_of_order: newId }
          : plant
      )
      return [...updatedPlants, newPlant]
    })

    setHasUnsavedChanges(true)
    setExpandedEntryId(newId)

    // Scroll to the new entry after a short delay to ensure the DOM has updated
    setTimeout(() => {
      scrollToElement(`plant-row-${newId}`)
    }, 100)
  }

  useEffect(() => {
    if (expandedEntryId) {
      scrollToElement(`plant-row-${expandedEntryId}`)
    }
  }, [expandedEntryId, scrollToElement])

  const handleSendToRFP = () => {
    setIsCreateRFPModalOpen(true)
  }

  const handleEntryUpdate = (updatedPlant: PlantListEntry) => {
    setLocalPlants((plants) =>
      plants.map((plant) =>
        plant.id === updatedPlant.id ? updatedPlant : plant
      )
    )
    setHasUnsavedChanges(true)
  }

  const handleEntryClicked = (plantId: string) => {
    setExpandedEntryId(expandedEntryId === plantId ? null : plantId)
  }

  const handleSaveChanges = () => {
    onUpdate(localPlants)
    setHasUnsavedChanges(false)
  }

  return (
    <>
      <FilterBar
        files={plantList.files}
        rfpIds={rfpIds}
        onFileFilterChange={setFileFilter}
        onRfpFilterChange={setRfpFilter}
        onCommonNameFilterChange={setCommonNameFilter}
        onScientificNameFilterChange={setScientificNameFilter}
      />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <Paper sx={{ width: '100%', mb: 2 }}>
          <StyledTableContainer ref={tableContainerRef}>
            <Table stickyHeader ref={tableRef}>
              <TableHead
                sx={{
                  '& .MuiTableCell-head': {
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(4px)',
                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                  },
                }}
              >
                <TableRow>
                  <TableCell padding="none" style={{ width: '48px' }} />
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedPlants.size > 0 &&
                        selectedPlants.size < filteredPlants.length
                      }
                      checked={
                        selectedPlants.size === filteredPlants.length &&
                        filteredPlants.length > 0
                      }
                      onChange={() => {
                        if (selectedPlants.size === filteredPlants.length) {
                          setSelectedPlants(new Set())
                        } else {
                          setSelectedPlants(
                            new Set(filteredPlants.map((plant) => plant.id))
                          )
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Common Name</TableCell>
                  <TableCell align="center">Scientific Name</TableCell>
                  <TableCell align="center">Specs</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <SortableContext
                  items={filteredPlants.map((plant) => plant.id)}
                  strategy={verticalListSortingStrategy}
                >
                  {filteredPlants.map((plant, index) => (
                    <Fragment key={plant.id}>
                      <SortablePlantItem
                        plant={plant}
                        isSelected={selectedPlants.has(plant.id)}
                        onSelect={() => toggleSelect(plant.id)}
                        onClick={handleEntryClicked}
                        onUpdate={handleEntryUpdate}
                        optionsEnums={optionsEnums}
                        testId={`plant-row-${index}`}
                      />
                      {expandedEntryId === plant.id && (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Box>
                              <PlantListEntryForm
                                entry={plant}
                                fileProcessingData={fileProcessingData}
                                onUpdate={handleEntryUpdate}
                                files={plantList.files}
                                optionsEnums={optionsEnums}
                                id={`plant-row-${plant.id}`}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))}
                </SortableContext>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box display="flex" justifyContent="space-between">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveChanges}
                        disabled={!hasUnsavedChanges}
                        startIcon={<SaveIcon />}
                      >
                        Save Changes
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddPlant}
                        startIcon={<AddIcon />}
                      >
                        Add Plant
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        disabled={selectedPlants.size === 0}
                        startIcon={<DeleteIcon />}
                      >
                        Remove Selected Plants
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </StyledTableContainer>
        </Paper>
      </DndContext>
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendToRFP}
          disabled={selectedPlants.size === 0}
          startIcon={<Send />}
        >
          Send Selected to RFP
        </Button>
      </Box>
      {isCreateRFPModalOpen && (
        <CreateRFPModal
          plantList={plantList}
          selectedPlantIds={selectedPlants}
          onClose={() => setIsCreateRFPModalOpen(false)}
          onRFPCreated={onRFPCreated}
        />
      )}
    </>
  )
}
