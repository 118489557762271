import { Auth0Provider } from '@auth0/auth0-react'
import { ThemeProvider } from '@mui/material'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'

import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '@/constants.ts'

import App from './App.tsx'
import router from './router.ts'
import theme from './theme.ts'

import './global.css'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

const queryClient = new QueryClient()

Sentry.init({
  environment: import.meta.env.VITE_ENV,
  dsn: 'https://4853b3a020d700c1f1f563e4fc26fc5c@o4504952823087104.ingest.us.sentry.io/4507487732760576',
  integrations: [
    Sentry.tanstackRouterBrowserTracingIntegration(router),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      mask: [
        'input[type="password"]',
        'input[type="email"]',
        '.sensitive-data',
      ],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 0.1,
  profilesSampleRate: 0.1,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/auth0-callback`,
        audience: AUTH0_AUDIENCE,
        scope:
          'openid profile email read:current_user update:current_user_metadata offline_access update:current_user_identities read:current_user_identities',
      }}
      useRefreshTokens={true}
      cacheLocation={'localstorage'}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </QueryClientProvider>
    </Auth0Provider>
  </React.StrictMode>
)
