import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { styled } from '@mui/material/styles'
import { useQuery } from '@tanstack/react-query'
import { usePubNub } from '@/contexts/PubNubContext'
import {
  Fragment,
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react'

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import PlantListEntryForm from './plant-list-entry-form.tsx'
import SortablePlantItemAlternative from './sortable-plant-item-alt.tsx'

import { getOptions, getPlantListById } from '@/api/plant-list.ts'
import type { AIProcessingData, PlantList, PlantListEntry } from '@/types.ts'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import { EyeIcon } from 'lucide-react'

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: '60vh',
  overflow: 'auto',
  position: 'relative',
  '& .MuiTableFooter-root': {
    position: 'sticky',
    bottom: 0,
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 -1px 2px rgba(0, 0, 0, 0.1)',
  },
}))

type PlantListProps = {
  plantList: PlantList
  selectedFiles: string[]
  fileProcessingData: AIProcessingData[]
  selectedPlants: Set<string>
  setSelectedPlants: (selectedPlants: Set<string>) => void
  setTargetFileId: (fileId: string) => void
}

interface FileDataType {
  [key: string]: {
    user_file_name: string
  }
}

export default function PlantListTableAlternative({
  plantList,
  selectedFiles,
  fileProcessingData,
  selectedPlants,
  setSelectedPlants,
  setTargetFileId,
}: PlantListProps) {
  const { subscribeToChannels, unsubscribeFromChannels } = usePubNub()
  const [localPlants, setLocalPlants] = useState<PlantListEntry[]>(
    plantList.entries
  )
  const [fileData] = useState<FileDataType>(
    plantList.files.reduce((acc: FileDataType, file) => {
      acc[file.id] = file
      return acc
    }, {} as FileDataType)
  )
  const [expandedEntryId, setExpandedEntryId] = useState<string | null>(null)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [fileFilter] = useState('')
  const [rfpFilter] = useState('')
  const [commonNameFilter] = useState('')
  const [scientificNameFilter] = useState('')

  const tableRef = useRef<HTMLTableElement>(null)
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const scrollToElement = useCallback((elementId: string) => {
    const element = document.getElementById(elementId)
    const container = tableContainerRef.current
    const header = tableRef.current?.querySelector('thead')

    if (element && container && header) {
      const headerHeight = header.getBoundingClientRect().height
      const elementPosition = element.getBoundingClientRect().top
      const containerPosition = container.getBoundingClientRect().top
      const offsetPosition =
        elementPosition - containerPosition - headerHeight - 10 // 10px extra padding

      container.scrollTo({
        top: container.scrollTop + offsetPosition,
        behavior: 'smooth',
      })
    }
  }, [])

  const { data: optionsEnums } = useQuery({
    queryKey: ['optionEnums'],
    queryFn: getOptions,
  })

  useEffect(() => {
    setLocalPlants(plantList.entries)
    setHasUnsavedChanges(false)
  }, [plantList])

  useEffect(() => {
    const channels = fileProcessingData
      ?.filter((data: AIProcessingData) => !data.processing_completed)
      .map((data: AIProcessingData) => data.pubsub_channel)

    if (!channels || channels.length === 0) {
      return
    }

    const listener = async (messageEvent: any) => {
      console.log('collapsible-plant-list message', messageEvent.message)
      const { channel_id, model } = messageEvent.message
      // find the file that matches the channel_id
      const fileIndex = fileProcessingData.findIndex(
        (data) => data.pubsub_channel === channel_id
      )
      if (fileIndex === -1) {
        return
      }
      const updatedFileProcessing = [...fileProcessingData]
      updatedFileProcessing[fileIndex] = {
        ...updatedFileProcessing[fileIndex],
        processing_errors: model.processing_errors,
        processing_completed: model.processing_completed,
      }

      // if model.processing_completed is true, refetch the plant list
      if (model.processing_completed) {
        console.log('current local plants:', localPlants)
        const plantListData = await getPlantListById(plantList.id)
        if (!plantListData || !plantListData.entries.length) {
          return
        }
        console.log('plantListData:', plantListData)
        // get any entries from current plant list that are new
        const newEntries = localPlants.filter((entry) => entry.is_new)
        console.log('newEntries:', newEntries)
        console.log('local plants:', localPlants)

        if (newEntries.length > 0) {
          plantListData.entries[
            plantListData.entries.length - 1
          ].parent_of_order = newEntries[0].id
          // update the order of the new entries
          newEntries.forEach((entry, index) => {
            entry.parent_of_order =
              index === newEntries.length - 1 ? null : newEntries[index + 1].id
          })
        }
        // add the new entries to the plant list
        const updatedEntries = [...plantListData.entries, ...newEntries]
        setLocalPlants(updatedEntries)
      }
    }

    subscribeToChannels(channels, 'plantList', listener)

    return () => {
      unsubscribeFromChannels(channels, 'plantList')
    }
  }, [fileProcessingData, plantList.id, plantList.entries, localPlants])

  const deletedFileIds = useMemo(() => {
    return new Set(
      plantList.files.filter((file) => file.deleted_at).map((file) => file.id)
    )
  }, [plantList.files])

  const [filteredPlantLength, setFilteredPlantLength] = useState(0)
  const filteredPlants = useMemo(() => {
    return localPlants.reduce(
      (acc: Record<string, PlantListEntry[]>, plant: PlantListEntry) => {
        if (plant.file_id && selectedFiles.includes(plant.file_id as string)) {
          if (!acc[plant.file_id]) {
            acc[plant.file_id] = []
          }
          acc[plant.file_id].push(plant)
        }
        return acc
      },
      {} as Record<string, PlantListEntry[]>
    )
  }, [
    deletedFileIds,
    fileFilter,
    commonNameFilter,
    localPlants,
    rfpFilter,
    scientificNameFilter,
    selectedFiles,
  ])
  useEffect(() => {
    setFilteredPlantLength(Object.values(filteredPlants).flat().length)
  }, [filteredPlants])
  console.log('filteredPlantsAlt:', filteredPlants)

  // const rfpIds = useMemo(() => {
  //   const ids = new Set<string>()
  //   localPlants.forEach((plant) =>
  //     plant.rfp_id.forEach((id) => ids.add(String(id)))
  //   )
  //   return Array.from(ids)
  // }, [localPlants])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over, delta } = event
    const movedDown = delta.y > 0

    if (active.id === over?.id) {
      return
    }

    if (!over) {
      return
    }

    setLocalPlants((plants) => {
      const oldIndex = plants.findIndex((plant) => plant.id === active.id)
      const parentOfActive = plants.find(
        (plant) => plant.parent_of_order === active.id
      )
      const activePlant = plants[oldIndex]
      const newIndex = plants.findIndex((plant) => plant.id === over.id)
      const parentOfOver = plants.find(
        (plant) => plant.parent_of_order === over.id
      )
      const overPlant = plants[newIndex]

      if (parentOfActive) {
        parentOfActive.parent_of_order = activePlant.parent_of_order
      }
      if (parentOfOver && !movedDown) {
        parentOfOver.parent_of_order = activePlant.id
      }
      if (movedDown) {
        activePlant.parent_of_order = overPlant.parent_of_order
        overPlant.parent_of_order = active.id.toString()
      } else {
        activePlant.parent_of_order = over.id.toString()
      }

      const newPlants = arrayMove(plants, oldIndex, newIndex)
      setHasUnsavedChanges(true)
      return newPlants
    })
  }

  const toggleSelect = (plantId: string) => {
    const newSelectedPlants = new Set(selectedPlants)
    if (newSelectedPlants.has(plantId)) {
      newSelectedPlants.delete(plantId)
    } else {
      newSelectedPlants.add(plantId)
    }
    setSelectedPlants(newSelectedPlants)
  }

  // const handleDelete = () => {
  //   setLocalPlants((plants) =>
  //     plants.map((plant) => {
  //       if (selectedPlants.has(plant.id)) {
  //         return { ...plant, deleted_at: new Date().toISOString() }
  //       }
  //       return plant
  //     })
  //   )
  //   setHasUnsavedChanges(true)
  //   setSelectedPlants(new Set())
  // }

  // const handleAddPlant = () => {
  //   const newId = generateObjectId()
  //   const newPlant: PlantListEntry = {
  //     ...DEFAULT_PLANT_LIST_ENTRY,
  //     id: newId,
  //     is_new: true,
  //   }
  //   setLocalPlants((prevPlants) => {
  //     const updatedPlants = prevPlants.map((plant) =>
  //       plant.parent_of_order === null
  //         ? { ...plant, parent_of_order: newId }
  //         : plant
  //     )
  //     return [...updatedPlants, newPlant]
  //   })
  //
  //   setHasUnsavedChanges(true)
  //   setExpandedEntryId(newId)
  //
  //   // Scroll to the new entry after a short delay to ensure the DOM has updated
  //   setTimeout(() => {
  //     scrollToElement(`plant-row-${newId}`)
  //   }, 100)
  // }

  useEffect(() => {
    if (expandedEntryId) {
      scrollToElement(`plant-row-${expandedEntryId}`)
    }
  }, [expandedEntryId, scrollToElement])

  const handleEntryUpdate = (updatedPlant: PlantListEntry) => {
    setLocalPlants((plants) =>
      plants.map((plant) =>
        plant.id === updatedPlant.id ? updatedPlant : plant
      )
    )
    setHasUnsavedChanges(true)
  }

  const handleEntryClicked = (plantId: string) => {
    setExpandedEntryId(expandedEntryId === plantId ? null : plantId)
  }

  // const handleSaveChanges = () => {
  //   onUpdate(localPlants)
  //   setHasUnsavedChanges(false)
  // }

  console.log('unused', hasUnsavedChanges)

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <Paper sx={{ width: '100%', mb: 2, borderRadius: 0 }}>
        <StyledTableContainer ref={tableContainerRef}>
          <Table stickyHeader ref={tableRef}>
            <TableHead
              sx={{
                '& .MuiTableCell-head': {
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  backdropFilter: 'blur(4px)',
                  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <TableRow>
                <TableCell
                  padding="none"
                  sx={{ boxShadow: 'none !important', width: '48px' }}
                />
                <TableCell
                  padding="checkbox"
                  sx={{ boxShadow: 'none !important' }}
                >
                  <Checkbox
                    indeterminate={
                      selectedPlants.size > 0 &&
                      selectedPlants.size < filteredPlantLength
                    }
                    checked={
                      selectedPlants.size === filteredPlantLength &&
                      filteredPlantLength > 0
                    }
                    onChange={() => {
                      if (selectedPlants.size === filteredPlantLength) {
                        setSelectedPlants(new Set())
                      } else {
                        setSelectedPlants(new Set(Object.keys(filteredPlants)))
                      }
                    }}
                  />
                </TableCell>
                <TableCell align="left" sx={{ boxShadow: 'none !important' }}>
                  PLANT NAME
                </TableCell>
                <TableCell align="center" sx={{ boxShadow: 'none !important' }}>
                  QTY
                </TableCell>
                <TableCell align="left" sx={{ boxShadow: 'none !important' }}>
                  SPECS
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ boxShadow: 'none !important' }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <SortableContext
                items={Object.values(filteredPlants)
                  .flat()
                  .map((plant: PlantListEntry) => plant.id)}
                strategy={verticalListSortingStrategy}
              >
                {Object.entries(filteredPlants).map(
                  ([file, plantEntries]: [string, PlantListEntry[]]) => (
                    <Fragment key={file}>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="h6" display="flex" gap={0.7}>
                              {`${plantEntries.length} plants`}
                              <Typography fontWeight="normal" variant="h6">
                                {` extracted from ${fileData[file].user_file_name}`}
                              </Typography>
                            </Typography>
                            <Button
                              variant="text"
                              size="small"
                              startIcon={<EyeIcon size={16} />}
                              onClick={() => setTargetFileId(file)}
                              sx={{
                                '&:hover': { backgroundColor: 'grey.100' },
                              }}
                            >
                              View File
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                      {plantEntries.map(
                        (plant: PlantListEntry, index: number) => (
                          <Fragment key={plant.id}>
                            <SortablePlantItemAlternative
                              plant={plant}
                              isSelected={selectedPlants.has(plant.id)}
                              onSelect={() => toggleSelect(plant.id)}
                              onClick={handleEntryClicked}
                              onUpdate={handleEntryUpdate}
                              optionsEnums={optionsEnums}
                              testId={`plant-row-${index}`}
                            />
                            {expandedEntryId === plant.id && (
                              <TableRow>
                                <TableCell colSpan={6}>
                                  <PlantListEntryForm
                                    entry={plant}
                                    fileProcessingData={fileProcessingData}
                                    onUpdate={handleEntryUpdate}
                                    files={plantList.files}
                                    optionsEnums={optionsEnums}
                                    id={`plant-row-${plant.id}`}
                                  />
                                </TableCell>
                              </TableRow>
                            )}
                          </Fragment>
                        )
                      )}
                    </Fragment>
                  )
                )}
              </SortableContext>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Paper>
    </DndContext>
  )
}
