import { type SxProps } from '@mui/material'
import { type ReactNode } from 'react'

import Box from '@mui/material/Box'

interface TabPanelProps {
  children?: ReactNode
  id: string
  index: number
  value: number
  sx?: SxProps
}
export default function TabPanel({ children, value, index, id, sx }: TabPanelProps) {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`${id}-tabpanel-${index}`}
      aria-labelledby={`${id}-tab-${index}`}
      sx={{ width: '100%', overflowY: 'auto', ...sx }}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  )
}
