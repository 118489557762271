import { styled } from '@mui/system'
import IconButton from '@mui/material/IconButton'
import EditOutlined from '@mui/icons-material/EditOutlined'
import React, { ReactNode } from 'react'
import AddIcon from '@mui/icons-material/Add'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: '1px solid #e0e0e0',
  borderRadius: '5px',
  padding: theme.spacing(1), // Optional, adds some padding
  '&:hover': {
    // borderColor: theme.palette.primary.main, // Optional, change border on hover
  },
}))

type StyledIconButtonProps = {
  children?: ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const StyledAddIcon = ({ children, onClick }: StyledIconButtonProps) => (
  <StyledIconButton onClick={onClick}>
    {children || <AddIcon />}
  </StyledIconButton>
)

const StyledPencilIcon = ({ children, onClick }: StyledIconButtonProps) => (
  <StyledIconButton onClick={onClick}>
    {children || <EditOutlined />}
  </StyledIconButton>
)

export default StyledPencilIcon
