import { FormControl, Select, MenuItem } from '@mui/material'
import { format, setHours, setMinutes } from 'date-fns'
import { SelectChangeEvent } from '@mui/material/Select'

type TimeDropdownProps = {
  name: string
  selectedTime: string
  setSelectedTime: (name: string, time: string) => void
}

export const TimeDropdown = ({
  name,
  selectedTime,
  setSelectedTime,
}: TimeDropdownProps) => {
  // Generate time options
  const timeOptions = Array.from({ length: 24 }, (_, index) => {
    const date = setMinutes(setHours(new Date(), index), 0)
    return format(date, 'h:mm a')
  })

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedTime(name, event.target.value)
  }

  return (
    <FormControl>
      <Select
        fullWidth
        variant="outlined"
        labelId={`${name}-time-select-label`}
        id={`${name}-time-select`}
        value={selectedTime}
        onChange={handleChange}
        sx={{ maxWidth: '150px' }}
      >
        {timeOptions.map((time) => (
          <MenuItem key={`${name}-time-${time}`} value={time}>
            {time}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
