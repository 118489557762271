import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import SearchBar, { SearchBarProps } from '@/components/ui/base/search-bar'

import TabPanel from './tab-panel'
import FilterPanel from './filter-panel'

type FilterConfig = Parameters<typeof FilterPanel>[0]
type TabConfig = Parameters<typeof TabPanel>[0]

interface ControlPanelProps<T> {
  filterConfig?: FilterConfig
  searchConfig: Omit<SearchBarProps<T>, 'ref'>
  tabConfig: TabConfig
}
export default function ControlPanel<T>({
  filterConfig,
  searchConfig,
  tabConfig,
}: ControlPanelProps<T>) {
  return (
    <Paper elevation={0}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: { xs: 'column', xl: 'row' },
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            width: '100%',
          }}
        >
          <Box sx={{ flexShrink: 0 }}>
            <TabPanel {...tabConfig} />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ flexGrow: 1, minWidth: 0 }}>
            <SearchBar<T> {...searchConfig} />
          </Box>
        </Box>

        {filterConfig && (
          <>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start' } }}>
              <FilterPanel {...filterConfig} />
            </Box>
          </>
        )}
      </Box>
    </Paper>
  )
}
